
import { Col, Row , Image , Button , Alert , DatePicker , Input , Select , Form , message } from 'antd';
import {checkUsername , checkEmail , register} from '../services/auth-service';
import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

function Register(props) {
  const [loading, setLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refer = queryParams.get('refer');
 

  const [unamehelp , setUnameHelp] = useState(null);   
  const [unamestatus , setUnameStatus] = useState(null);   

  const [emailhelp , setEmailHelp] = useState(null);   
  const [emailstatus , setEmailStatus] = useState(null);
  
  const [confpasshelp , setConfPassHelp] = useState(null);   
  const [confpassstatus , setConfPassStatus] = useState(null);



  const onUsernameChange = (e) => {
    var txt = e.target.value
    if(txt.length >= 5){
      const chkuname = async() => {
        let success =  await checkUsername(txt);
        if(success){
          setUnameStatus("success");
        }
        else{
          setUnameStatus("error");
          setUnameHelp("Username is already taken!");
        }
      }
      chkuname();

    }
    else{
      setUnameStatus("error");
      setUnameHelp("Username must have atleast 5 characters.");
    }
  }

  const onEmailChange = (e) => {
    var txt = e.target.value
    const chkemail = async() => {
      let success =  await checkEmail(txt);
      if(!success){
        setEmailStatus("error");
        setEmailHelp("There is an account with this email!");
      }
      else{
        setEmailStatus(null);
        setEmailHelp(null);
      }
    }
    chkemail();
   
  }



  const onFinish = (values) => {
    
    values['dob'] = values['dob'].format('YYYY-MM-DD');
    values['referred_by'] = refer;
    if(values['password'] != values['conf_password']){
      setConfPassStatus("error");
      setConfPassHelp("Passwords don't match!")

    }
    else if(emailstatus != "error" && unamestatus != "error"){
      setLoading(true);

      setConfPassStatus(null);
      setConfPassHelp(null);
      
      const execregister = async() => {
        let result =  await register(values);
        if(result){
          messageApi.open({
            type: 'success',
            content: 'Registration Successful! Please confirm your email.',
          });
          await new Promise(resolve => setTimeout(resolve, 2000));
          setLoading(false);
          props.cngDrawerComp('login');
        }
        else{
          setLoading(false);
          messageApi.open({
            type: 'error',
            content: 'Unable to register! Please try again later!',
          });
          
        }
      }
      execregister();




     



    }
  }

  const onFinishFailed = (errorInfo) => {
    
  }


  

  const [form] = Form.useForm();
  const country_list = ["Afghanistan", 	"Albania", 	"Algeria", 	"American Samoa", 	"Andorra", "Angola", 
                        	"Anguilla", 	"Antarctica", 	"Antigua and Barbuda", 	"Argentina", 	"Armenia", 	"Aruba", 	
                          "Australia", 	"Austria", 	"Azerbaijan", 	"Bahamas", 	"Bahrain", 	"Bangladesh", 	"Barbados", 	
                          "Belarus", 	"Belgium", 	"Belize", 	"Benin", 	"Bermuda", 	"Bhutan", 	"Bolivia (Plurinational State of)", 	
                          "Bonaire, Sint Eustatius and Saba", 	"Bosnia and Herzegovina", 	"Botswana", 	"Bouvet Island", 	"Brazil", 	
                          "British Indian Ocean Territory", 	"Brunei Darussalam", 	"Bulgaria", 	"Burkina Faso", 	"Burundi", 	"Cabo Verde", 	
                          "Cambodia", 	"Cameroon", 	"Canada", 	"Cayman Islands", 	"Central African Republic", 	"Chad", 	"Chile", 	"China", 	
                          "Christmas Island", 	"Cocos Keeling Islands", 	"Colombia", 	"Comoros", 	"Congo (the Democratic Republic of the)", 	
                          "Congo", 	"Cook Islands", 	"Costa Rica", 	"Croatia", 	"Cuba", 	"Curaçao", 	"Cyprus", 	"Czechia", 	"Côte d'Ivoire", 	
                          "Denmark", 	"Djibouti", 	"Dominica", 	"Dominican Republic", 	"Ecuador", 	"Egypt", 	"El Salvador", 	"Equatorial Guinea", 	
                          "Eritrea", 	"Estonia", 	"Eswatini", 	"Ethiopia", 	"Falkland Islands", 	"Faroe Islands", 	"Fiji", 	"Finland", 	"France", 	
                          "French Guiana", 	"French Polynesia", 	"French Southern Territories", 	"Gabon", 	"Gambia", 	"Georgia", 	"Germany", 	"Ghana", 	
                          "Gibraltar", 	"Greece", 	"Greenland", 	"Grenada", 	"Guadeloupe", 	"Guam", 	"Guatemala", 	"Guernsey", 	"Guinea", 	"Guinea-Bissau", 	
                          "Guyana", 	"Haiti", 	"Heard Island and McDonald Islands", 	"Holy See", 	"Honduras", 	"Hong Kong", 	"Hungary", 	"Iceland", 	"India", 	
                          "Indonesia", 	"Iran (Islamic Republic of)", 	"Iraq", 	"Ireland", 	"Isle of Man", 	"Israel", 	"Italy", 	"Jamaica", 	"Japan", 	"Jersey", 	
                          "Jordan", 	"Kazakhstan", 	"Kenya", 	"Kiribati", 	"Korea (the Democratic People's Republic of)", 	"Korea (the Republic of)", 	"Kuwait", 	
                          "Kyrgyzstan", 	"Lao People's Democratic Republic", 	"Latvia", 	"Lebanon", 	"Lesotho", 	"Liberia", 	"Libya", 	"Liechtenstein", 	"Lithuania", 	
                          "Luxembourg", 	"Macao", 	"Madagascar", 	"Malawi", 	"Malaysia", 	"Maldives", 	"Mali", 	"Malta", 	"Marshall Islands", 	"Martinique", 	
                          "Mauritania", 	"Mauritius", 	"Mayotte", 	"Mexico", 	"Micronesia (Federated States of)", 	"Moldova (the Republic of)", 	"Monaco", 	"Mongolia", 	
                          "Montenegro", 	"Montserrat", 	"Morocco", 	"Mozambique", 	"Myanmar", 	"Namibia", 	"Nauru", 	"Nepal", 	"Netherlands", 	"New Caledonia", 	"New Zealand", 	
                          "Nicaragua", 	"Niger", 	"Nigeria", 	"Niue", 	"Norfolk Island", 	"Northern Mariana Islands", 	"Norway", 	"Oman", 	"Pakistan", 	"Palau", 
                          "Palestine, State of", 	"Panama", 	"Papua New Guinea", 	"Paraguay", 	"Peru", 	"Philippines", 	"Pitcairn", 	"Poland", 	"Portugal", 	
                          "Puerto Rico", 	"Qatar", 	"Republic of North Macedonia", 	"Romania", 	"Russian Federation", 	"Rwanda", 	"Réunion", 	"Saint Barthélemy", 	
                          "Saint Helena, Ascension and Tristan da Cunha", 	"Saint Kitts and Nevis", 	"Saint Lucia", 	"Saint Martin (French part)", 	
                          "Saint Pierre and Miquelon", 	"Saint Vincent and the Grenadines", 	"Samoa", 	"San Marino", 	"Sao Tome and Principe", 	
                          "Saudi Arabia", 	"Senegal", 	"Serbia", 	"Seychelles", 	"Sierra Leone", 	"Singapore", 	"Sint Maarten (Dutch part)", 	
                          "Slovakia", 	"Slovenia", 	"Solomon Islands", 	"Somalia", 	"South Africa", 	"South Georgia and the South Sandwich Islands", 	
                          "South Sudan", 	"Spain", 	"Sri Lanka", 	"Sudan", 	"Suriname", 	"Svalbard and Jan Mayen", 	"Sweden", 	"Switzerland", 	"Syrian Arab Republic", 	
                          "Taiwan", 	"Tajikistan", 	"Tanzania, United Republic of", 	"Thailand", 	"Timor-Leste", 	"Togo", 	"Tokelau", 	"Tonga", 	"Trinidad and Tobago", 	
                          "Tunisia", 	"Turkey", 	"Turkmenistan", 	"Turks and Caicos Islands", 	"Tuvalu", 	"Uganda", 	"Ukraine", 	"United Arab Emirates", 	
                          "United Kingdom of Great Britain and Northern Ireland", 	"United States Minor Outlying Islands", 	"United States of America", 	"Uruguay", 	
                          "Uzbekistan", 	"Vanuatu", 	"Venezuela (Bolivarian Republic of)", 	"Viet Nam", 	"Virgin Islands (British)", 	
                          "Virgin Islands (U.S.)", 	"Wallis and Futuna", 	"Western Sahara", 	"Yemen", 	"Zambia", 	"Zimbabwe", 	"Åland Islands" ];     

    return (
      <div>
        {contextHolder}
           <span className='font-semibold text-raven-pink text-2xl w-full block text-center mt-10'>Register</span>

          
           <div  className='block mt-6'>
          <Alert
            message="Error Messages"
            type="error"
            showIcon
            className='mt-3 bg-red-300 border-red-300 text-red-800 rounded-3l hidden'
          />
          
           <Form
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
           <Form.Item name = "username" hasFeedback   validateStatus={unamestatus} help = {unamehelp} rules={[{ required: true, message: 'Please input your username!' } , { min: 5, message: 'Username must have atleast 5 characters.' }]}>
           <Input placeholder="Username" className='h-12 rounded-3xl bg-raven-mid-blue mt-3' onChange = {onUsernameChange}/>
           </Form.Item>
           <Form.Item name = "email" hasFeedback validateStatus={emailstatus} help = {emailhelp} rules={[{ required: true, message: 'Please input your email!' }, {type: 'email', message: 'The input is not a valid email!'},]}>
           <Input placeholder="email" className='h-12 rounded-3xl bg-raven-mid-blue' onChange = {onEmailChange}/>
           </Form.Item>
           <Form.Item name = "gender" rules={[{ required: true, message: 'Please select your gender!' }]}>
           <Select placeholder="Gender" className='h-12 rounded-3xl bg-raven-mid-blue w-full'  options={[
                        { value: 'M', label: 'male'},
                        { value: 'F', label: 'female'}
                    ]} />
                    </Form.Item>

            <Form.Item name = "dob" rules={[{ required: true, message: 'Please pick your birth date!' }]}> 
           <DatePicker placeholder="Date of Birth" className='h-12 rounded-3xl bg-raven-mid-blue w-full' />
           </Form.Item>
           <Form.Item name = "country" rules={[{ required: true, message: 'Please select your country!' }]}>
           <Select placeholder="Country" className='h-12 rounded-3xl bg-raven-mid-blue w-full'  options={
                      country_list.map((map) => ({value: map, label: map }))
            } />
            </Form.Item>
            <Form.Item name = "password" rules={[{ required: true, message: 'Please input your password!' } ,
                     
                      {
                        min: 7,
                        message: 'Password must be at least 7 characters.',
                      },
                      {
                        pattern: /^(?=.*[0-9]).*$/,
                        message: 'Password must contain at least one number.',
                      },
            ]}>
           <Input.Password placeholder="Password" className='h-12 rounded-3xl bg-raven-mid-blue' />
           </Form.Item>
            <Form.Item name = "conf_password" hasFeedback validateStatus = {confpassstatus} help = {confpasshelp}  rules={[{ required: true, message: 'Please input your password again!' }]}>
           <Input.Password  placeholder="Confirm Password" className='h-12 rounded-3xl bg-raven-mid-blue' />
           </Form.Item>
            <Form.Item>
            <Button type="primary" shape="round" size="default" htmlType="submit" className='bg-raven-pink h-12 w-full text-lg' loading = {loading} >
              Register
            </Button>
             </Form.Item>
            
            </Form>  
            </div>
            <span className='font-semibold float-left block text-raven-light-blue mt-2 pb-5 w-full text-center text-base'>Already have an account? <span className='text-raven-pink cursor-pointer' onClick={() =>  props.cngDrawerComp('login')}>Login</span></span>
            
            



      </div>
    );
  }
  
  export default Register;