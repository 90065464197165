
import {Button , Alert , Input , Form } from 'antd';
import {forgotPassword} from '../services/auth-service';
import React, { useState } from 'react';

function ForgotPassword(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errmsg , setErrMsg] = useState(null);
  const [sucmsg , setSucMsg] = useState(null);
  

  const onFinish = (values) => {
    const execforgotpass = async() => {
      setLoading(true);
      let result = await forgotPassword(values['email']);
      if(result[0]){
        setLoading(false);
        setSucMsg(result[1]);
      }
      else{
        setLoading(false);
        setErrMsg(result[1]);
      }
    }
    execforgotpass();

  }


    return (
      <div>
           <span className='font-semibold text-raven-pink text-2xl w-full block text-center mt-10'>Forgot Password</span>

          
           <div  className='block mt-6'>
                <Alert
                    message={errmsg}
                    type="error"
                    className='mt-3 bg-red-300 border-red-300 text-red-800 rounded-3l'
                    style = {{display : errmsg ? "block" : "none"}}
                />
               
               <Alert
                    message={sucmsg}
                    type="success"
                    className='mt-3 bg-green-300 border-green-300 text-green-800 rounded-3l'
                    style = {{display : sucmsg ? "block" : "none"}}
                />
                
            </div>
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: false }}
                onFinish={onFinish}
                
                autoComplete="off"
              >
                 <Form.Item name = "email"  rules={[{ required: true, message: 'Please input your email!' }]}>
                   <Input placeholder="Enter Your Email" className='h-12 rounded-3xl bg-raven-mid-blue mt-3' />
                 </Form.Item>
                 <Form.Item>
                 <Button type="primary" shape="round" size="default" htmlType="submit"  className='bg-raven-pink mt-1 h-12 w-full text-lg'  loading = {loading}>
                    Submit
                 </Button>
                 </Form.Item>



              </Form>
           
            <span className='text-gray-300 cursor-pointer mt-3 m-auto block w-fit text-[17px] font-semibold' onClick={() => props.cngDrawerComp('login')}>
              Cancel
            </span>
          
           </div>
    );
  }
  
  export default ForgotPassword;