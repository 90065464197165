
import {fetchBlogDetails , endBlogRead} from '../services/blog-service';
import {useEffect, useState , useRef} from 'react';
import { useParams } from 'react-router-dom';
import { Row,Col } from 'antd';
import '../App.css';
import logo from '../assets/logo-2.png';
import AdsenseComponent from '../components/adsense-component';



var first_time = true;

function BlogDetails(){
    const[blog_details , setBlogDetails] = useState({});
    let {blog_name} = useParams();


    const headerTags = (title, description , image, link) => {

        document.title = title;
        document
              .querySelector('meta[name="description"]')
              .setAttribute('content', description);
        document
              .querySelector('meta[property="og:title"]')
              .setAttribute('content', title);
        document
              .querySelector('meta[property="og:image"]')
              .setAttribute('content', image);
        document
              .querySelector('meta[property="og:url"]')
              .setAttribute('content', link);
        document
              .querySelector('meta[name="twitter:title"]')
              .setAttribute('content', title);
        document
              .querySelector('meta[name="twitter:description"]')
              .setAttribute('content', description);
        document
              .querySelector('meta[name="twitter:image"]')
              .setAttribute('content', image);
    
      }

    


    const addAttributesToLinks = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
    
        const links = doc.querySelectorAll('a');
        links.forEach(link => {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer');
        });
    
        return doc.body.innerHTML;
      };

    useEffect(() => {
        const getBlogDetails = async() => {
            let bdetails =  await fetchBlogDetails(blog_name);
            bdetails.article = addAttributesToLinks(bdetails.article);
            localStorage.setItem('blogsessid' , bdetails.session)
            setBlogDetails(bdetails);
            headerTags(bdetails.title, bdetails.snippet_text ,process.env.REACT_APP_BASE_URL + bdetails.thumbnail, window.location) 
             



        }
        getBlogDetails();
        




        return () => {


            if(!first_time){

                const endBlog = async() => {
                    let b =  await endBlogRead(localStorage.getItem('blogsessid'));
                    console.log('H')
                    localStorage.removeItem('blogsessid');
                }
                endBlog();
                
            }
            else{
                first_time = false; 
            }
       
        }

        



    } , []);

    return (
        <div>
           
        
        <Row className='mt-[10px]'>
            <Col xs={1} sm={1} md={2} lg={5} xl={5} xxl={6}>
           
                    <div className='w-[160px] h-[600px] lg:block hidden mr-5 float-right sticky top-[250px]'>
                    
                    <AdsenseComponent  adSlot="1361557201" adClient="ca-pub-4208207038795686" width = "160px" height = "600px"/>
           

                    </div>
            
            
            </Col>
            <Col xs = {22} sm = {22} md = {20}  lg = {14} xl = {14}  xxl = {11} >

            <div className='bg-[#0C0D14] sticky xs:top-[188px] sm:top-[188px] md:top-[128px] xl:top-[64px] mt-[-30px] z-10 mb-[20px]'>

            
                <div style = {{margin : 'auto' , width : 'fit-content'}} className='xs:hidden sm:hidden md:hidden lg:block  z-10'>
                <AdsenseComponent  adSlot="6446362923" adClient="ca-pub-4208207038795686" width = "728px" height = "90px"/>
           
                </div> 
                <div style = {{margin : 'auto' , width : 'fit-content'}} className='xs:block sm:block md:block lg:hidden z-10'>
                
                <AdsenseComponent  adSlot="2674638879" adClient="ca-pub-4208207038795686" width = "320px" height = "50px"/>
           
                </div>
            </div>
                
                <div className='blog'>
                    <h1 className='leading-normal'>{blog_details.title}</h1>
                    <div className='blog-auth'>
                        <div display="flex" className="blog-auth-details" bis_skin_checked="1">
                            <img src={logo} alt="ravenarena" />
                            <div className='blog-auth-text'>
                            <h6 style={{fontSize : 'large'}}>By RavenArena</h6>
                            <span className="blog-auth-text-date">{new Date(blog_details.published_date).toLocaleDateString('en-us', {month:"long", day:"numeric" , year:"numeric"})}</span>
                            </div> 
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: blog_details.article}}></div>
                </div>
            </Col>

            <Col xs={1} sm={1} md={2} lg={5} xl={5} xxl={6}>

            <div className='w-[160px] h-[600px] lg:block hidden ml-5 float-left sticky top-[250px]'>
            
            <AdsenseComponent  adSlot="8016700167" adClient="ca-pub-4208207038795686" width = "160px" height = "600px"/>

            </div>

            
            
            </Col>

        </Row>
        
        </div>
    );

}
export default BlogDetails;