import { apiClient , apiClient2 } from './api-config';

export const sendGeneralFeedback = async (rating , message) => {
    try {
      const response = await apiClient.post('feedback/submit/' , {rating : rating , message : message} );
      return true;
    } catch (error) {
      return false;
    }
};

export const checkGeneralFeedback = async () => {
    try {
      const response = await apiClient.post('feedback/check/' , {} );
      return response.data.status;
    } catch (error) {
      return false;
    }
};



