
import {Button , Alert , Input , Form } from 'antd';
import {resetPassword} from '../services/auth-service';
import {useNavigate} from 'react-router-dom';
import React, { useState } from 'react';

function NewPassword(props) {

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errmsg , setErrMsg] = useState(null);
  const [sucmsg , setSucMsg] = useState(null);

  const [confpasshelp , setConfPassHelp] = useState(null);   
  const [confpassstatus , setConfPassStatus] = useState(null);



  const onFinish = (values) => {

    

    if(values['password'] != values['conf_password']){
      setConfPassStatus("error");
      setConfPassHelp("Passwords don't match!")

    }
    else{
      const resetpass = async() => {
        setLoading(true);
        let result = await resetPassword(props.uid , props.token, values['password']);
        if(result[0]){
          setLoading(false);
          setSucMsg(result[1]);
          navigate("/?login=1");
        }
        else{
          setLoading(false);
          setErrMsg(result[1]);
        }
      }
      resetpass();
    }

  }


    return (
      <div>
           <span className='font-semibold text-raven-pink text-2xl w-full block text-center mt-10'>New Password</span>

          
           <div  className='block mt-6'>
                <Alert
                    message={errmsg}
                    type="error"
                    className='mt-3 bg-red-300 border-red-300 text-red-800 rounded-3l'
                    style = {{display : errmsg ? "block" : "none"}}
                />
               
               <Alert
                    message={sucmsg}
                    type="success"
                    className='mt-3 bg-green-300 border-green-300 text-green-800 rounded-3l'
                    style = {{display : sucmsg ? "block" : "none"}}
                />
                <Form
                form={form}
                name="basic"
                initialValues={{ remember: false }}
                onFinish={onFinish}
                className='mt-2'
                autoComplete="off"
              >
                    <Form.Item name = "password" rules={[{ required: true, message: 'Please input your password!' } ,
                     
                     {
                       min: 7,
                       message: 'Password must be at least 7 characters.',
                     },
                     {
                       pattern: /^(?=.*[0-9]).*$/,
                       message: 'Password must contain at least one number.',
                     },
                    ]}>
                       <Input.Password placeholder="Password" className='h-12 rounded-3xl bg-raven-mid-blue' />
                    </Form.Item>
                    <Form.Item name = "conf_password" hasFeedback validateStatus = {confpassstatus} help = {confpasshelp}  rules={[{ required: true, message: 'Please input your password again!' }]}>
                        <Input.Password  placeholder="Confirm Password" className='h-12 rounded-3xl bg-raven-mid-blue' />
                    </Form.Item>
                    
                    <Form.Item>
                      <Button type="primary" shape="round" htmlType="submit" size="default"  className='bg-raven-pink mt-2 h-12 w-full text-lg'>
                        Reset Password
                      </Button>

                    </Form.Item>
                    </Form>
            </div>
           
          
           </div>
    );
  }
  
  export default NewPassword;