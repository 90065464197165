import { apiClient } from './api-config';

export const getBalance = async () => {
  try {
    const response = await apiClient.get('wallet/balance/');
    return response.data.balance;
  } catch (error) {
    return null;
  }
};

export const getDepositAddress = async () => {
    try {
      const response = await apiClient.get('wallet/getdepositaddress/?coin=USDT&network=BSC');
      return response.data.address;
    } catch (error) {
      return null;
    }
  };

  export const withdraw = async (amount,address) => {
    try {
      const response = await apiClient.post('wallet/withdraw/', {amount : amount , address : address , coin : 'USDT' , network : 'BSC'});
      return [true, 'success'];
    } catch (error) {
      return [false, error.response.data.reason];
    }
  };