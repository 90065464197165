import axios from 'axios';
import { apiClient } from './api-config';

export const profile = async () => {
    try {
      const response = await apiClient.get('/account/get_account_details/');
      return [true , response.data];
    } catch (error) {
      console.log(error)
      return [false, error.response.data.message];
    }
};

export const updateProfile = async ({username , gender ,dob,country}) => {
  try {
    const body = {username : username , gender : gender , dob : dob , country : country};
    const response = await apiClient.post('/account/update/' , body);
    const profile = await apiClient.get('/account/get_account_details/');
    localStorage.setItem('profile' , JSON.stringify(profile.data.account_details));
    return [true , response.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};