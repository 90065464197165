
import { Row,Col, Button , Table , Popover   } from 'antd';
import { ArrowLeftOutlined , FullscreenOutlined , InfoCircleOutlined , PlayCircleOutlined , CommentOutlined} from '@ant-design/icons';
import AdsenseComponent from '../components/adsense-component';

import Loading from '../components/loading-component';
import Page404 from '../pages/404-page';
import {useEffect, useState , useRef} from 'react';
import { useParams } from 'react-router-dom';

import {play , joinTournament , fetchTournamentDetails , fetchLeaderboard} from '../services/tournament-service';
import { useOutletContext } from "react-router-dom";
import Share from '../components/share';

function TournamentDetails() {

  let {tournament_name} = useParams();
  const iframeRef = useRef();

  let timestamp = null;

  const {setOpenDrawer,setDrawerComp} = useOutletContext();
  const [tourndetailsloaded, setTournamentDetailsLoaded] = useState(false); 
  const [tdetailserr, setTDetailsErr] = useState(false); 
  const [tdetails, setTDetails] = useState({});  
  const [leaderboard, setLeaderBoard] = useState({});  
  const [ctime, setCountDownTime] = useState(null);  
  const [showpopup, setShowPopup] = useState(false);
  const [showfeedbackpopup, setFeedbackPopup] = useState(false); 


  const rankSup = (rank) => {
    if(rank == 1){
       return "st";
    }
    else if(rank == 2){
      return "nd";
    }
    else if(rank == 3){
      return "rd";
    }
    else{
      return "th";
    }
  }

  const togglePopup = () => {
    setShowPopup(!showpopup);
  }

  const displayLeaderBoard = async (id,rank) => {

    let lb = [];
    let l = [];

    let profile = localStorage.getItem('profile');
    let username = null;
    if(profile != null){
      profile = JSON.parse(profile);
      username = profile.username;
    }



    if(rank != null){
      lb =  await fetchLeaderboard(id , "rank=" + rank)
    }
    else{
      lb =  await fetchLeaderboard(id , "")
    }

    const rank_start = lb['rank_start'];
    lb = lb['participants'];

    for (let i = 0; i < lb.length; i++) {

      if(lb[i]['username'] == username){

        l.push({
          rank: <span className='font-bold'>{rank_start +i+1}</span>,
          player: <span className='font-bold'>{lb[i]['username']}</span>,
          score: <span className='font-bold'>{lb[i]['current_highest_score']}</span>,
          winnings : <span className='font-bold'>${lb[i]['winnings']}</span>,
          
        })

      }
      else{
        l.push({
          rank: rank_start + i + 1,
          player: lb[i]['username'],
          score: lb[i]['current_highest_score'],
          winnings : lb[i]['winnings'],
          
        });

      }
      

    }

    setLeaderBoard(l);
    
  }


  const myLeaderBoard = async() => {
    await displayLeaderBoard(tdetails['id'] , tdetails['rank']);

  }

  const topLeaderBoard = async () => {
    await displayLeaderBoard(tdetails['id'] , null);

  }

  const handlefeedbackChange = (newOpen) => {
    setFeedbackPopup(newOpen);
  };

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (3600*24));
    seconds -= days * 3600 * 24;
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    seconds = Math.floor(seconds); // Remove any fractional part
    
    // Format the time in XD:YH:ZM:AS format
    return `${days}D:${hours}H:${minutes}M:${seconds}S`;
  }

  const updateCountdown = () => {
    // Format and display the time
    const formattedTime = formatTime(timestamp);
    setCountDownTime(formattedTime);
    
    // Decrement the timestamp by one second
    timestamp--;

    // If the countdown reaches zero, stop the timer
    if (timestamp < 0) {
        const formattedTime = formatTime(0);
        setCountDownTime(formattedTime);
    }
}

  useEffect( () => {
    setTournamentDetailsLoaded(false);
    const getTournamentDetails = async() => {

      console.log(tournament_name);
      let tournament =  await fetchTournamentDetails(tournament_name);

      


      console.log(tournament);
      if(tournament == null){
        setTDetailsErr(true);
        setTournamentDetailsLoaded(true);
      }
      else{

        await displayLeaderBoard(tournament['id'] , tournament['rank']);


        
        if(!tournament.game_details.game_link.startsWith("http")){
          tournament.game_details.game_link = process.env.REACT_APP_BASE_URL + tournament.game_details.game_link;
        }

        timestamp = tournament.diff;
        
        setTDetails(tournament);
       
        setTournamentDetailsLoaded(true);
        
      }
    }

    getTournamentDetails();

    const handleMessage = (event) => {
      // You can add security checks here by verifying event.origin
      if (event.data.finalScore !== undefined) {
          // Do something with the score
          console.log('Score from iframe: ', event.data.finalScore);
          getTournamentDetails();
      }

      if (event.data.token !== undefined) {
         console.log("incoming new token");
         localStorage.setItem("token" , event.data.token[0]);
         localStorage.setItem("refresh" , event.data.token[1]);
      }
    }


    window.addEventListener('message', handleMessage);
    const intervalId = setInterval(updateCountdown, 1000);

    

    


    return () => {
      // This function will be called when the component is unmounted (destroyed)
      clearInterval(intervalId);
      window.removeEventListener('message', handleMessage);
     
    };


   
    
  }, [tournament_name]);

  const sendFeedback = async() => {
    if(!localStorage.getItem('token')){
     setFeedbackPopup(true); 
     return; 
    }
    
    setDrawerComp('feedback');
    setOpenDrawer(true);
 
 
  }




  const startGame = async() => {

    if(localStorage.getItem('profile') == null){
      setOpenDrawer(true);
      return;
    }

    let joined = null;
    if(!tdetails.joined){
       let j = await joinTournament(tdetails.id);
       joined = j != null;
    }
    else{
      joined = true;
    }

    if(joined){

      let sess = await play(tdetails.id);

      if(sess != null){

        let cover = document.getElementById("playnowcover");
        let gamecanvas = document.getElementById("gamecanvas");


        let refresh = localStorage.getItem('refresh');
        let token = localStorage.getItem('token');
  
        gamecanvas.style.display = "block";
        gamecanvas.src = tdetails.game_details.game_link + "?r=" + refresh + "&t=" + token + "&sess=" + sess + "&tid=" + tdetails.id;
        cover.style.display = "none";
        gamecanvas.focus();
  
  
        if(window.innerWidth < 1024){
          enterFullScreen();
        }

      }

     

    }

    
  }

  const isIos = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  const exitFullScreen = () => {

    let element = document.getElementById("gamecanvas");

      let element_cont1 = document.getElementById("gamecanvas-cont1");
      let element_cont2 = document.getElementById("gamecanvas-cont2");
      let element_fullscreen = document.getElementById("fullscreen-menu");
      let body = document.getElementsByTagName("body")[0];
      body.style.overflowY = "scroll";

      element_cont1.classList.remove('gamecanvas-container');
      element_cont2.classList.remove('gamecanvas-container');
      element.classList.remove('gamecanvas-game');
      element_fullscreen.classList.remove('gamecanvas-menu');


  }
  

  const enterFullScreen = () => {

    if (isIos()) {

      let element = document.getElementById("gamecanvas");

      let element_cont1 = document.getElementById("gamecanvas-cont1");
      let element_cont2 = document.getElementById("gamecanvas-cont2");
      let element_fullscreen = document.getElementById("fullscreen-menu");
      let body = document.getElementsByTagName("body")[0];

      
      body.style.overflow = "hidden";
      element_cont1.classList.toggle('gamecanvas-container');
      element_cont2.classList.toggle('gamecanvas-container');
      element.classList.toggle('gamecanvas-game');
      element_fullscreen.classList.toggle('gamecanvas-menu');
     
    }
    else{

      let element = document.getElementById("gamecanvas");

      if(element.requestFullscreen) {
        element.requestFullscreen();
      }else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();     // Firefox
      }else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();  // Safari
      }else if(element.msRequestFullscreen) {
        element.msRequestFullscreen();      // IE/Edge
      }
      element.focus();
      
    }

   
  };




  

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      
    },
    {
      title: 'Player',
      dataIndex: 'player',
      key: 'player',
      render: (text) => <span> {text}</span>,
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      render: (text) => <span>{text}</span>,
    }
  ];


  const data = [
    {
      rank: '1',
      player: 'player0001',
      score: '2543',
      winnings : 750.00,
     
    },
    {
      rank: '2',
      player: 'player0002',
      score: '34',
      winnings : 550.00,
     
    },
    {
      rank: '3',
      player: 'player0003',
      score: '12',
      winnings : 550.00,
     
    },
    {
      rank: '4',
      player: 'player0004',
      score: '12',
      winnings : 350.00,
     
    },
    {
      rank: '5',
      player: 'player0005',
      score: '12',
      winnings : 250.00,
     
    },
    {
      rank: '6',
      player: 'player0006',
      score: '11',
      winnings : 150.00,
     
    },
  ]

  
  
    return (
      <div>

        

        {
        
            (!tourndetailsloaded) ? (
              <Loading />) : 
           (
          <div>

            {
              (tdetailserr) ? (<Page404 />)
            
             :
            (

          <div>

           <div className='h-[50px] bg-green-700 w-full block mt-[-1rem] justify-center sticky top-16 z-40'>

              <span className='text-white align-center m-auto w-fit block pt-2 font-sans text-lg'>Tournament ends in <span className='text-xl font-bold'>{ctime}</span></span>

          </div>
          <Row>
         
          <Col xs = {{span : 22 , offset : 1}} sm = {{span : 22 , offset : 1}} md = {{span : 20 , offset : 2}} lg = {{span : 0 , offset : 0}} xl = {{span : 0 , offset : 0}} xxl = {{span : 0 , offset : 0}} >
          
          <div className="mt-1 rounded-lg bg-cover bg-origin-border bg-center bg-[url('assets/WebRankBackground.png')] p-3 w-full mt-4">

                  <Row>

                    <Col span = {12}>
                       
                       <Row className='w-full'>
                        <Col span={24}>
                        <span className='text-blue-300 block text-md font-semibold font-sans block  w-full text-center'>
                          Your Highest Score
                        </span>
                        </Col>
                       </Row>
                       <Row className='w-full'>
                        <Col span={24}>
                        <span className='text-white block text-lg w-full text-center font-semibold font-sans block mt-1'>
                             {tdetails.high_score != null ? tdetails.high_score.toLocaleString('en-US') : 0}
                        </span>
                        </Col>
                       </Row>

                    </Col>

                    <Col span = {12}>

                      <Row className='w-full'>
                        <Col span={24}>
                        <span className='text-blue-300 block text-md font-semibold font-sans block  w-full text-center'>
                        Current Rank
                        </span>
                        </Col>
                       </Row>
                       <Row className='w-full'>
                        <Col span={24}>

                        {
                            (tdetails.rank == null) ? (

                              <span className='text-white block text-lg w-full text-center font-semibold font-sans block mt-1'>
                                     UNK
                              </span>

                            ) : (

                              <span className='text-white block text-lg w-full text-center font-semibold font-sans block mt-1'>
                              {tdetails.rank}<sup>{rankSup(tdetails.rank.toLocaleString('en-US'))}</sup> / <span className='text-sm'>{tdetails.players.toLocaleString('en-US')}</span>
                              </span>

                            )

                        }
                       
                        </Col>
                       </Row>
                      
                    </Col>

                    

                  </Row>
            </div>


          </Col>
        </Row>

        <Row className='mt-4'>

        <Col xs = {{span : 24 , offset : 0}} sm = {{span : 22 , offset : 1}} md = {{span : 20 , offset : 2}} lg = {{span : 17 , offset : 1}} xl = {{span : 16 , offset : 2}} xxl = {{span : 12 , offset : 4}}>
          <div style = {{margin : 'auto' , marginTop : '2px' , width : 'fit-content'}} className='xs:hidden sm:hidden md:hidden lg:block'>
          <AdsenseComponent  adSlot="6446362923" adClient="ca-pub-4208207038795686" width = "728px" height = "90px"/>
           
           </div> 
           <div style = {{margin : 'auto' , marginTop : '2px' , width : 'fit-content'}} className='xs:block sm:block md:block lg:hidden'>
            
           <AdsenseComponent  adSlot="2674638879" adClient="ca-pub-4208207038795686" width = "320px" height = "50px"/>
           
           
           </div> 
        </Col>
        
        <Col className='mt-2' xs = {{span : 24 , offset : 0}} sm = {{span : 22 , offset : 1}} md = {{span : 20 , offset : 2}}  lg = {{span : 17 , offset : 1}} xl = {{span : 16 , offset : 2}}  xxl = {{span : 12 , offset : 4}} >

           

          <div id="gamecanvas-cont1" className='block rounded-lg w-full bg-gray-500  xs:h-[30vh] sm:h-[40vh] md:h-[40vh] lg:h-[60vh] xl:h-[60vh] 2xl:h-[60vh] ' >

            <div id="gamecanvas-cont2" className='w-full h-full z-10 relative'>
            <iframe ref = {iframeRef} id="gamecanvas" style = {{display : "none"}}   className='w-full h-[calc(100%-40px)]' scrolling='no'
                  sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts allow-same-origin allow-downloads allow-popups"
                  allow="autoplay; payment; fullscreen; microphone; focus-without-user-activation *; screen-wake-lock; gamepad; clipboard-read; clipboard-write; "
                  marginwidth='0' marginheight='0' hspace='0' vspace='0' frameborder='0'
              
                /> 

                <div id="fullscreen-menu" className='h-[30px] bg-raven-blue-black w-full p-2'>
                     <ArrowLeftOutlined className='text-white ml-2 font-bold' onClick={exitFullScreen} />
                     <span className='ml-2 text-white font-bold'>{tdetails.game_details.title}</span>
                </div>

            <div className='h-10 w-full absolute bottom-0 z-10 bg-gray-700'>

            <span className='float-left ml-4 text-white text-lg font-semibold mt-1'>{tdetails.game_details.title}</span>
                  
            <FullscreenOutlined className='float-right mr-4 text-white text-xl mt-2' onClick={enterFullScreen}/>

            <Popover
              content={<div className='bg-raven-blue-black min-w-[200px]'>
                   <h2 className='text-white font-sans font-semibold text-xl mt-1'>Controls</h2>
                   <p className='text-gray-300 mt-5 font-sans'>
                 <div dangerouslySetInnerHTML={{__html: tdetails.game_details.controls}}></div>
                 </p>
              </div>}
              trigger="click"
              placement="topRight"
              open={showpopup}
              onOpenChange={null}
            >
              <InfoCircleOutlined className='float-right mr-4 text-white text-xl mt-2' onClick={togglePopup} />
            </Popover>

            <span className='float-right mr-4 text-gray-500 text-lg mt-[3px]' >|</span>
            

            <Popover
              content= {<div className='inline-block'><p className='text-white'>You need to login to comment.</p><Button type="primary" shape="round" size="default" className='float-right mt-3 bg-raven-pink' onClick={() => setOpenDrawer(true)}>Login</Button></div>}
              trigger="click"
              placement="topRight"
              style = {{display : localStorage.getItem('token') ? 'none' : 'block'}}
              open={localStorage.getItem('token') ? false : showfeedbackpopup}
              onOpenChange={handlefeedbackChange}
            >  
            <span className='float-right mr-4 text-md cursor-pointer mt-2' style = {{color : 'white'}} onClick={sendFeedback}><CommentOutlined /> Comment</span>
            </Popover>

                          

            </div>
            </div>

            <div id = "playnowcover" className={"flex w-full xs:h-[30vh] sm:h-[40vh] md:h-[40vh] lg:h-[60vh] xl:h-[60vh] 2xl:h-[60vh] absolute top-0 left-0 items-center justify-center z-20 overflow-hidden"}
                
            >

             <img alt = {tdetails.game_details.thumbnail} className='absoulte top:0 left:0 w-full h-full blur-lg ' style = {{backgroundImage: "url('" + process.env.REACT_APP_MEDIA_URL + tdetails.game_details.thumbnail +"')" , backgroundSize: 'cover',
                backgroundPosition: 'center'}} />
              <div className='absolute flex z-50 bg-gray-800/[.8]  top:0 left:0 w-full h-full items-center justify-center block'>
                <div>
                <h3 className='text-white text-2xl w-full text-center font-semibold'>{tdetails.game_details.title}</h3>
              <Button type="primary" shape="round" size="default" onClick = {startGame} className=' bg-raven-pink h-12 text-lg block items-center mt-5 m-auto' >
              <div><span>Play Now </span><PlayCircleOutlined className='ml-1'/></div>
              
            </Button>
            </div>
            </div>
       
            </div>
            
                    
          </div>

          <div style = {{margin : 'auto' , marginTop : '10px' , width : 'fit-content'}} className='xs:hidden sm:hidden md:hidden lg:block'>
          <AdsenseComponent  adSlot="6080536034" adClient="ca-pub-4208207038795686" width = "728px" height = "90px"/>
           </div> 
           <div style = {{margin : 'auto' , marginTop : '10px' , width : 'fit-content'}} className='xs:block sm:block md:block lg:hidden'>
            
           <AdsenseComponent  adSlot="3603057255" adClient="ca-pub-4208207038795686" width = "300px" height = "100px"/>
           </div> 

          


           </Col>
           { 
           
           //for tournament xs = {{span : 0}} sm = {{span : 0}} md = {{span : 0}} lg = {{span : 5}} xl = {{span : 4}} xxl = {{span : 4}} 
           //for game all are 0
           
           
           }
           <Col className='mt-2' xs = {{span : 0}} sm = {{span : 0}} md = {{span : 0}} lg = {{span : 5}} xl = {{span : 4}} xxl = {{span : 4}} >
           <div className='h-full ml-3'>
            <div className="p-5 rounded-lg bg-cover bg-origin-border bg-center bg-raven-blue-black h-fit pl-5 w-full bg-[url('assets/WebRankBackground.png')]">

              <span className='text-blue-300 lg:text-base xl:text-base 2xl:text-lg font-sans block mt-7 text-center'>
                Your Highest Score
              </span>
              <span className='text-white lg:text-xl 2xl:text-3xl font-semibold font-sans block mt-1 text-center'>
              {tdetails.high_score != null ? tdetails.high_score.toLocaleString('en-US') : 0}
              </span>
              <span className='text-blue-300 lg:text-base xl:text-base 2xl:text-lg font-sans block mt-7 text-center'>
               Current Rank
              </span>
              
              {
                            (tdetails.rank == null) ? (

                              <span className='text-white block text-lg  w-full text-center font-semibold font-sans block mt-1 mb-7'>
                               UNK
                           </span>

                            ) : (

                              <span className='text-white block lg:text-xl 2xl:text-3xl w-full text-center font-semibold font-sans block mt-1 mb-7'>
                              {tdetails.rank}<sup>{rankSup(tdetails.rank.toLocaleString('en-US'))}</sup> / <span className='lg:text-sm 2xl:text-lg'>{tdetails.players.toLocaleString('en-US')}</span>
                              </span>

                            )

                        }
                       
              
            </div>

            <div className='text-gray-400 p-3 w-full text-md'>
              ADVERTISEMENT HERE
              

              <div className='m-auto w-fit'>

              

              <AdsenseComponent  adSlot="6342421147" adClient="ca-pub-4208207038795686" width = "160px" height = "300px"/>

             
              </div>
              
            </div>
            </div>

            
           </Col>

        </Row>

        

        <Row className='mb-10'>

        
          <Col xs = {{span : 22 , offset : 1}} sm = {{span : 22 , offset : 1}} md = {{span : 20 , offset : 2}} lg = {{span : 12 , offset : 1}} xl = {{span : 13 , offset : 2}} xxl = {{span : 10 , offset : 4}}>
               <div className='w-full bg-raven-blue-black p-4 mt-4 rounded-xl h-full '>
                 <Share game_name={tdetails.game_details.title} url={window.location.href} type= "tournament" />
                 <h2 className='text-white font-sans font-semibold text-xl mt-5'>About the tournament</h2>
                 <p className='text-gray-300 mt-5 font-sans'>
                 <div dangerouslySetInnerHTML={{__html: tdetails.description}}></div>
                 </p>
                 <h2 className='text-white font-sans font-semibold mt-4 text-xl'>About the game</h2>
                 <p className='text-gray-300 mt-5 font-sans'>
                 <div dangerouslySetInnerHTML={{__html: tdetails.game_details.description}}></div>
                 </p>
                 <h2 className='text-white font-sans font-semibold text-xl mt-5'>Controls</h2>
                 <p className='text-gray-300 mt-5 font-sans'>
                 <div dangerouslySetInnerHTML={{__html: tdetails.game_details.controls}}></div>
                 </p>

                 
              
               </div>
          </Col>
          {
            //tournament xs = {{span : 22 , offset : 1}} sm = {{span : 22 , offset : 1}} md = {{span : 20 , offset : 2}} lg = {{span : 10, offset : 0}} xl = {{span : 7, offset : 0}} xxl = {{span : 6 , offset : 0}}
          }
          <Col xs = {{span : 22 , offset : 1}} sm = {{span : 22 , offset : 1}} md = {{span : 20 , offset : 2}} lg = {{span : 10, offset : 0}} xl = {{span : 7, offset : 0}} xxl = {{span : 6 , offset : 0}}>
          <div className="w-full bg-raven-blue-black p-4 xs:mt-8 sm:mt-8 md:mt-8 lg:mt-4 xl:mt-4 rounded-xl xs:ml-0 sm:ml-0 lg:ml-3 xl:ml-3 bg-[url('assets/WebRankBackground.png')]  bg-cover bg-center h-full">
          <span className='font-semibold text-white sm:text-xl xl:text-2xl w-full text-center block mt-5'>Leaderboard</span>
          <div className='mt-5'><Button type="primary" shape="round" size="default" onClick = {topLeaderBoard} className='bg-raven-pink h-10 text-base'>Show Top 10</Button>
          
          {
            (tdetails.rank != null) ? (
              <Button type="primary" shape="round" size="default" onClick = {myLeaderBoard} className='bg-raven-pink h-10 text-base ml-3'>Show My Rank</Button>

            ) : (<span></span>)
          }

          </div>
         


          <Table size = 'default' columns={columns} dataSource={leaderboard} className='mt-5 opacity-100 text-white' />
            </div>
          </Col>

          <Col xs = {{span : 1}} sm = {{span : 1}} md = {{span : 2}} lg = {{span : 1}} xl = {{span : 2}} xxl = {{span : 4}}>

            
          </Col>

        </Row>

        {/*

        <Row>
          <Col xs = {{span : 22 , offset : 1}} sm = {{span : 22 , offset : 1}} md = {{span : 20 , offset : 2}} lg = {{span : 22 , offset : 1}} xl = {{span : 18 , offset : 3}} xxl = {{span : 14 , offset : 5}} >
              
          <span className='font-semibold text-white text-lg block mt-10'>More Tournaments</span>
          
          <Tournaments className = 'mt-10'/>        
          
          </Col>
        </Row>

        */}



        

       
        
      
    </div>


    ) }
    </div>
    )
    }



    </div>

    );
  }
  
  export default TournamentDetails;