
import { Row,Col , Button } from 'antd';
import Games from '../components/gamelist-component';
import Loading from '../components/loading-component';
import {fetchGames} from '../services/game-service';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSearchParams } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";
import {getTournamentList} from '../services/tournament-service';
import FeedbackModal from '../components/general-feedback-component';
import Tournaments from '../components/tournamentlist-component';
import {sendGeneralFeedback, checkGeneralFeedback} from '../services/feedback-service';
import LevelupAndWinModal from '../components/winprize-component';


import img5klg from '../assets/win-5k-lg4.webp';
import img5ksm from '../assets/win-5k-sm4.webp';

function FeaturedPage() {

  const [gamesloaded, setGamesLoaded] = useState(false);  
  const [showfeedbackmodal, setShowFeedBackModal] = useState(false);  
  let [params , setSearchParams] = useSearchParams();
  const {setOpenDrawer,setDrawerComp} = useOutletContext();
  const [games, setGames] = useState({tournaments : [] , new : [], casual : [] , puzzle : [] , battle : [] , adventure : [] , dressup : [] , agility : [], simulation : [] , driving : [] , shooter : []  , sport : []}); 
  const navigate = useNavigate();

  useEffect( () => {

    const getGames = async() => {
      let newgames =  await fetchGames("limit=8&new=true");
      let casual =  await fetchGames("category=casual&limit=16&is_featured=true");
      let puzzle =  await fetchGames("category=puzzle&limit=16&is_featured=true");
      let battle =  await fetchGames("category=battle&limit=16&is_featured=true");
      let adventure =  await fetchGames("category=adventure&limit=16&is_featured=true");
      let dressup =  await fetchGames("category=dressup&limit=16&is_featured=true");
      let agility =  await fetchGames("category=agility&limit=16&is_featured=true");
      let simulation =  await fetchGames("category=simulation&limit=16&is_featured=true");
      let driving =  await fetchGames("category=driving&limit=16&is_featured=true");
      let shooter =  await fetchGames("category=shooter&limit=16&is_featured=true");
      let sport =  await fetchGames("category=sport&limit=16&is_featured=true");
      let tournaments = await getTournamentList();
      setGames({tournaments : tournaments , new : newgames, casual : casual , puzzle : puzzle , battle : battle , adventure : adventure , dressup : dressup , agility : agility , simulation : simulation , driving : driving , shooter : shooter , sport : sport});
      setGamesLoaded(true);
      
    }
    getGames();

    if(params.get('login') == "1"){
      setDrawerComp('login');
       setOpenDrawer(true);
    }


    const checkFeedBack = async() =>{
      let resp = await checkGeneralFeedback();
      if(resp == "ask_feedback"){
        setShowFeedBackModal(true);
      }

    }

    checkFeedBack();
   
    
  }, []); 

  const checkLevel = () => {

    if(localStorage.getItem('token')){
      setDrawerComp('profile');
      setOpenDrawer(true)
    }
    else{
      setDrawerComp('login');
      setOpenDrawer(true)
    }


    
  }


  const opencat = (cat) => {
    navigate('cat/'+cat)
  }

   
    return (
      <div>


        

        {
           !gamesloaded ? (<Loading />) : (

              <div>
              <LevelupAndWinModal show={!localStorage.getItem('token') && !localStorage.getItem('no_win_modal')} setOpenDrawer = {setOpenDrawer} setDrawerComp = {setDrawerComp} />
              <FeedbackModal show = {showfeedbackmodal} />
              <Row>
              <Col xs = {{span : 22 , offset : 1}} sm = {{span : 22 , offset : 1}} md = {{span : 22 , offset : 1}}  lg = {{span : 22 , offset : 1}} xl = {{span : 22 , offset : 1}}  xxl = {{span : 22 , offset : 1}}>
                
             


             

                
                <div className='w-full block mt-2 mb-2 flex items-center' style = {{display : (games.tournaments.length > 0) ? 'flex' : 'none'}}><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit' >Tournaments</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => navigate('tournaments/')} >View more</span></div>
                <Tournaments list = {games.tournaments}/>

                {
                  (games.new.length > 0) && (
                    <div>
                    <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>New Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => navigate('/new')}>View more</span></div>
                    <Games list = {games.new} />
                    </div>
                  )
                }
                <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>Casual Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => opencat('casual')}>View more</span></div>
                <Games list = {games.casual} />

                <div className='w-full bg-[#001529] mt-3 mr-3 hidden lg:block'>
                    <Row>
                        <Col lg = {15} xl = {11} xxl = {10}>


                          <img src = {img5klg} />
                        
                        </Col>

                        <Col lg = {9} xl = {13} xxl = {14}>

                        <h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit mb-2 mt-3'>Reach <span className='text-green-500 font-bold'>Level 2</span>, Earn Rewards</h1>
                        <p className='text-gray-200 mr-5'>

                        Join our online gaming adventure and level up to Level 2 to unlock your chance to share in a $5,000 reward! It's our way of celebrating your achievements in our gaming community. Start playing now for your slice of the prize!

                        </p>

                        <p className='text-gray-200 mt-2'> To reach Level 2, You will need to</p>

                        <ul className='text-gray-400 mt-3 list-disc ml-5'>
                          <li>Refer your friends to join this site</li>
                          <li>Play games for atleast 1 minute.</li>
                          <li>Like games</li>
                          <li>Give feedback on games</li>
                          <li>Read Blogs for atleast 1 minute</li>
                          <li>Share games on social media</li>
                        </ul>

                        <Button type="primary" shape="round" size="default" htmlType="submit" className='bg-raven-pink h-12 text-lg mt-3 mb-5' onClick={checkLevel} >
                          {localStorage.getItem('token') ? 'Check your Level' : 'Signup Now'}
                        </Button>
                            
                        </Col>

                    </Row>

                </div>

                <div className='w-full bg-[#001529] mt-3 mr-3 block lg:hidden'>
                    
                        


                    <div className='w-full h-[200px] sm:h-[320px] md:h-[400px]'>
                      <img src = {img5ksm} className='w-full'/>
                    </div>

                    <div className='ml-3 mr-3'>

                    <h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit mb-2'>Reach <span className='text-green-500 font-bold'>Level 2</span>, Earn Rewards</h1>
                    <p className='text-gray-200'>

                    Join our online gaming adventure and level up to Level 2 to unlock your chance to share in a $5,000 reward! It's our way of celebrating your achievements in our gaming community. Start playing now for your slice of the prize!

                    </p>

                    <p className='text-gray-200 mt-2'> To reach Level 2, You will need to</p>

                    <ul className='text-gray-400 mt-3 list-disc ml-5'>
                      <li>Refer your friends to join this site</li>
                      <li>Play games for atleast 1 minute.</li>
                      <li>Like games</li>
                      <li>Give feedback on games</li>
                      <li>Read Blogs for atleast 1 minute</li>
                      <li>Share games on social media</li>
                    </ul>

                    <Button type="primary" shape="round" size="default" htmlType="submit" className='bg-raven-pink h-12 text-lg mt-3 mb-3' onClick={checkLevel}>
                      {localStorage.getItem('token') ? 'Check your Level' : 'Signup Now'}
                    </Button>

                    </div>
                        
                    

                  </div>
                
                
                
                
                <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>Puzzle Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => opencat('puzzle')}>View more</span></div>
                <Games list = {games.puzzle} />
                <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>Battle Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => opencat('battle')}>View more</span></div>
                <Games list = {games.battle} />
                <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>Sports Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => opencat('sport')}>View more</span></div>
                <Games list = {games.sport} />
                <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>Agility Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => opencat('agility')}>View more</span></div>
                <Games list = {games.agility} />
                <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>Adventure Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => opencat('adventure')}>View more</span></div>
                <Games list = {games.adventure} />
                <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>Driving Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => opencat('driving')}>View more</span></div>
                <Games list = {games.driving} />
                <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>Shooter Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => opencat('shooter')}>View more</span></div>
                <Games list = {games.shooter} />
                <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>Simulation Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => opencat('simulation')}>View more</span></div>
                <Games list = {games.simulation} />
                <div className='w-full block mt-2 mb-2 flex items-center'><h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit'>Dress-up Games</h1><span className='text-raven-pink ml-3 font-semibold text-md cursor-pointer mt-[4px]' onClick = {() => opencat('dressup')}>View more</span></div>
                <Games list = {games.dressup} />
              </Col>
            </Row>


            
            </div>
             ) 



        }

       

         

      </div>
    );
  }
  
  export default FeaturedPage;