import { Image } from 'antd';
import logo from '../assets/Logo.png';
import { useState } from 'react';
import { Button, Modal , Rate , Form,Input } from 'antd';
import {sendGeneralFeedback, checkGeneralFeedback} from '../services/feedback-service';
import { useEffect } from 'react';
import img5kxl from '../assets/win-5k-xl.jpg';


function LevelupAndWinModal(props) {

    const [isModalOpen, setIsModalOpen] = useState(props.show);
    
    useEffect( () => {
        
        /*const getGames = async() => {
          let gameslst =  await sendGeneralFeedback("limit=60&category="+cat_name);
          setGames(gameslst);
          setGamesLoaded(true);
        }
    
        getGames();*/


        
       
        
    }, []); 


    const closeModal = () =>{
        setIsModalOpen(false);
        localStorage.setItem('no_win_modal' , 'nothanks')
    }


    const openLogin = () =>{
        props.setOpenDrawer(true)
        props.setDrawerComp('login')
        setIsModalOpen(false)
    }


 


    
   
    return (
       <div>
            <Modal 
             
              title="" 
              open={isModalOpen} 
              onOk={props.ok} 
              onCancel={closeModal} 
              closable = {false} 
              centered = {true} 
              maskClosable={false}

              footer = {null}

              width = {800}

              okButtonProps={{ type: "primary", style: { backgroundColor: '#1DA57A', borderColor: '#1DA57A' } }}
              cancelButtonProps={{ type: "primary", style: { backgroundColor: '#f55d42', borderColor: '#f55d42' }  }}
              okText="Sign Up!!"
              cancelText="No Thanks"
            
            >

                <img src = {img5kxl} className='w-full'/>
                 <h1 className='mt-5 m-auto text-white xs:text-lg sm:text-xl md:text-2xl text-center font-bold font-sans w-fit mb-2'>Reach <span className='text-green-500 font-bold'>Level 2</span>, Earn Rewards</h1>
                    
                    <p className='text-gray-200 text-center mt-5 mb-5 md:text-lg'>

                    Join our online gaming adventure and level up to Level 2 to unlock your chance to share in a $5,000 reward! Start playing now for your slice of the prize!

                    </p>

                    <p className='text-green-200 md:text-lg mt-2 text-center'> To reach Level 2, You will need to earn points by</p>

                    <ul className='text-gray-400 md:text-lg mt-3 list-disc block w-fit m-auto justify-center'>
                    <li>Refering your friends to join this site using your referal link.</li>
                    <li>Playing games for atleast 1 minute.</li>
                    <li>Liking games</li>
                    <li>Giving feedback on the games you played</li>
                    <li>Reading Blogs for atleast 1 minute</li>
                    <li>Sharing the games you like on social media</li>
                    </ul>

                    <Button type="primary" shape="round" size="default" htmlType="submit" className='bg-raven-pink h-12 text-lg mt-5 mb-3 m-auto block' onClick={openLogin} >
                        Signup Now
                    </Button>

                    <span className='font-semibold block text-raven-light-blue mt-1 w-full text-center text-base'><span className='text-raven-pink cursor-pointer' onClick = {closeModal}>No Thanks</span></span>
            

                    


            </Modal>
       </div>
 
     );
   }
   
export default LevelupAndWinModal;