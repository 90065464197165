
import { Row,Col, Button , Popover , Tag  } from 'antd';
import { ArrowLeftOutlined , FullscreenOutlined , CommentOutlined ,PlayCircleOutlined , LikeOutlined , DislikeOutlined , PlusOutlined } from '@ant-design/icons';
import Games from '../components/gamelist-component';
import Loading from '../components/loading-component';
import {fetchGameDetails , updateSentiment , updatePlayLater, addPlay, endPlay} from '../services/game-service';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState , useRef} from 'react';
import { useParams } from 'react-router-dom';
import AdsenseComponent from '../components/adsense-component';
import Page404 from '../pages/404-page';
import { useOutletContext } from "react-router-dom";
import Share from '../components/share';
import LevelupAndWinModal from '../components/winprize-component';

import TournamentBar from '../components/tournaments-bar';


function GameDetails() {

  const {setOpenDrawer,setDrawerComp} = useOutletContext();
  const [gamedetailsloaded, setGameDetailsLoaded] = useState(false);  
  const [gamedetailserr, setGameDetailsErr] = useState(false);
  const [gamedetails, setGameDetails] = useState({}); 
  const [showpopup, setShowPopup] = useState(false); 
  const [showlikeloginpopup, setLikeShowLoginPopup] = useState(false); 
  const [showdislikeloginpopup, setDislikeShowLoginPopup] = useState(false); 
  const [showplaylaterpopup, setplaylaterPopup] = useState(false); 
  const [showfeedbackpopup, setFeedbackPopup] = useState(false); 

  

  const [sentiment, setSentiment] = useState(false); 
  const [like_count, setLikeCount] = useState(0); 
  const [dislike_count, setDislikeCount] = useState(0); 
  const [play_later, setPlayLater] = useState(false);


  const navigate = useNavigate();




  let {game_name} = useParams();

  const iframeRef = useRef();

  const handleOpenChange = (newOpen) => {
    setLikeShowLoginPopup(newOpen);
  };

  const handledisOpenChange = (newOpen) => {
    setDislikeShowLoginPopup(newOpen);
  };

  const handleplaylaterChange = (newOpen) => {
    setplaylaterPopup(newOpen);
  };

  const handlefeedbackChange = (newOpen) => {
    setFeedbackPopup(newOpen);
  };


  const headerTags = (title, description , image, link) => {

    document.title = title;
    document
          .querySelector('meta[name="description"]')
          .setAttribute('content', description);
    document
          .querySelector('meta[property="og:title"]')
          .setAttribute('content', title);
    document
          .querySelector('meta[property="og:image"]')
          .setAttribute('content', image);
    document
          .querySelector('meta[property="og:url"]')
          .setAttribute('content', link);
    document
          .querySelector('meta[name="twitter:title"]')
          .setAttribute('content', title);
    document
          .querySelector('meta[name="twitter:description"]')
          .setAttribute('content', description);
    document
          .querySelector('meta[name="twitter:image"]')
          .setAttribute('content', image);

  }


  useEffect( () => {

    
    setGameDetailsLoaded(false);
    const getGameDetails = async() => {
      let game =  await fetchGameDetails(game_name);
      if(game == null){
        setGameDetailsErr(true);
        setGameDetailsLoaded(true);
      }
      else{
        if(!game.game_link.startsWith("http")){
          game.game_link = process.env.REACT_APP_BASE_URL + game.game_link;
        }
        

        headerTags(game.title + ' - Play on Ravenarena', game.description.split('\n')[0] ,process.env.REACT_APP_BASE_URL + game.thumbnail, window.location) 
             



        setGameDetails(game);
        setLikeCount(game.likes);
        setDislikeCount(game.dislikes);
        if(game.sentiment){
          setSentiment(game.sentiment);
        }
        if(game.saved_for_later){
          setPlayLater(true);
        }
        setGameDetailsLoaded(true);
      }
    }

    getGameDetails();


    return () => {
      // This function will be called when the component is unmounted (destroyed)
      console.log('END')

      const gameEnd = async() => {
        console.log(localStorage.getItem('sessid'));
        await endPlay(localStorage.getItem('sessid'));
        localStorage.removeItem('sessid');
      }

      if(localStorage.getItem('sessid') != null){
        gameEnd();
      }
      
      
     
    };


   
    
  }, [game_name]);

  const likeGame = async() => {

     if(!localStorage.getItem('token')){
        setLikeShowLoginPopup(true); 
        return; 
     }

     if(sentiment == "like"){
       let updated =  await updateSentiment(gamedetails.id , "remove_like");
       if(updated){
        setSentiment(false);
        setLikeCount(like_count - 1);
       }
     }
     else if(sentiment == "dislike"){
        let updated =  await updateSentiment(gamedetails.id , "like");
        if(updated){
          setSentiment("like");
          setLikeCount(like_count + 1);
          setDislikeCount(dislike_count - 1);
        }
     }
     else{
      let updated =  await updateSentiment(gamedetails.id , "like");
      if(updated){
        setSentiment("like");
        setLikeCount(like_count + 1);
      }
     }
  }

  const dislikeGame = async() => {

    if(!localStorage.getItem('token')){
      setDislikeShowLoginPopup(true);  
      return;
   }

    
    if(sentiment == "dislike"){
      let updated =  await updateSentiment(gamedetails.id , "remove_dislike");
      if(updated){
       setSentiment(false);
       setDislikeCount(dislike_count - 1);
      }
    }
    else if(sentiment == "like"){
       let updated =  await updateSentiment(gamedetails.id , "dislike");
       if(updated){
         setSentiment("dislike");
         setLikeCount(like_count - 1);
         setDislikeCount(dislike_count + 1);
       }
    }
    else{
     let updated =  await updateSentiment(gamedetails.id , "dislike");
     if(updated){
       setSentiment("dislike");
       setDislikeCount(dislike_count + 1);
     }
    }
 }

 const sendFeedback = async() => {
   if(!localStorage.getItem('token')){
    setFeedbackPopup(true); 
    return; 
   }
   
   setDrawerComp('feedback');
   setOpenDrawer(true);


 }

 const playGameLater = async() => {


   if(!localStorage.getItem('token')){
    setplaylaterPopup(true); 
    return; 
   }


    if(play_later){
      let updated =  await updatePlayLater(game_name , "remove");
      if(updated){
        setPlayLater(false);
      }
      
    }
    else{
      let updated =  await updatePlayLater(game_name , "add");
      if(updated){
        setPlayLater(true);
      }
    }
 }

  const togglePopup = () => {
    setShowPopup(!showpopup);
  }

  const isIos = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  const exitFullScreen = () => {

    let element = document.getElementById("gamecanvas");

      let element_cont1 = document.getElementById("gamecanvas-cont1");
      let element_cont2 = document.getElementById("gamecanvas-cont2");
      let element_fullscreen = document.getElementById("fullscreen-menu");
      let body = document.getElementsByTagName("body")[0];
      body.style.overflowY = "scroll";

      element_cont1.classList.remove('gamecanvas-container');
      element_cont2.classList.remove('gamecanvas-container');
      element.classList.remove('gamecanvas-game');
      element_fullscreen.classList.remove('gamecanvas-menu');


  }
  

  const enterFullScreen = () => {

    if (isIos()) {

      let element = document.getElementById("gamecanvas");

      let element_cont1 = document.getElementById("gamecanvas-cont1");
      let element_cont2 = document.getElementById("gamecanvas-cont2");
      let element_fullscreen = document.getElementById("fullscreen-menu");
      let body = document.getElementsByTagName("body")[0];

      
      body.style.overflow = "hidden";
      element_cont1.classList.toggle('gamecanvas-container');
      element_cont2.classList.toggle('gamecanvas-container');
      element.classList.toggle('gamecanvas-game');
      element_fullscreen.classList.toggle('gamecanvas-menu');
     
    }
    else{

      let element = document.getElementById("gamecanvas");

      if(element.requestFullscreen) {
        element.requestFullscreen();
      }else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();     // Firefox
      }else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();  // Safari
      }else if(element.msRequestFullscreen) {
        element.msRequestFullscreen();      // IE/Edge
      }
      element.focus();
      
    }

   
  };

  const startGame = async() => {
    let cover = document.getElementById("playnowcover");
    let gamecanvas = document.getElementById("gamecanvas");

    let res = await addPlay(game_name);
    localStorage.setItem('sessid' , res.id)

    gamecanvas.style.display = "block";
    gamecanvas.src = gamedetails.game_link;
    cover.style.display = "none";
    gamecanvas.focus();


    if(window.innerWidth < 1024){
      enterFullScreen();
    }
  }


    return (
      <div id="game-page">

        

        {
        
            (!gamedetailsloaded) ? (
              <Loading />) : 
           (
          <div>

            {
              (gamedetailserr) ? (<Page404 />)
            
             :
            (
              <div>
                <TournamentBar />

          <LevelupAndWinModal show={!localStorage.getItem('token') && !localStorage.getItem('no_win_modal')} setOpenDrawer = {setOpenDrawer} setDrawerComp = {setDrawerComp} />
              

                
                
           
        <Row className='mt-0'>

          <Col xs={0} sm={0} xl = {4} xxl = {6}>
          <div className='float-right mr-2 block mt-[0px]'>
          
          <AdsenseComponent  adSlot="1361557201" adClient="ca-pub-4208207038795686" width = "160px" height = "600px"/>
          

          <div className='max-w-[150px] mt-[250px]'>

          <Games query= {"page=4&exclude=" + gamedetails.id +"&limit=5&category=" + gamedetails.category}  />
           

          </div>
           
          
          </div>
         
         </Col>

            

           <Col xs = {{span : 24 , offset : 0}} sm = {{span : 22 , offset : 1}} md = {{span : 22 , offset : 1}}  lg = {{span : 22 , offset : 1}} xl = {{span : 16 , offset : 0}}  xxl = {{span : 12 , offset : 0}}>

          
          <div style = {{margin : 'auto' , marginTop : '0px' , width : 'fit-content', marginBottom : '10px'}} className='xs:hidden sm:hidden md:hidden lg:block xl:hidden'>

           <AdsenseComponent  adSlot="6446362923" adClient="ca-pub-4208207038795686" width = "728px" height = "90px"/>
           
            </div>
           <div style = {{margin : 'auto' , marginTop : '0px' , width : 'fit-content' , marginBottom : '10px'}} className='xs:block sm:block md:block lg:hidden'>

           <AdsenseComponent  adSlot="2674638879" adClient="ca-pub-4208207038795686" width = "320px" height = "50px"/>
           

           </div> 
         
          <div id="gamecanvas-cont1" className=' mt-[0px] block rounded-lg w-full bg-gray-500  xs:h-[30vh] sm:h-[40vh] md:h-[40vh] lg:h-[60vh] xl:h-[60vh] 2xl:h-[60vh] relative' >
          
            <div  id="gamecanvas-cont2" className='w-full h-full z-10 relative'>

               <iframe ref = {iframeRef} id="gamecanvas" style = {{display : "none"}}   className='w-full h-[calc(100%-40px)]' scrolling='no'
                  sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts allow-same-origin allow-downloads allow-popups"
                  allow="autoplay; payment; fullscreen; microphone; focus-without-user-activation *; screen-wake-lock; gamepad; clipboard-read; clipboard-write; "
                  marginwidth='0' marginheight='0' hspace='0' vspace='0' frameborder='0'
              
                /> 
                <div id="fullscreen-menu" className='h-[30px] bg-raven-blue-black w-full p-2'>
                     <ArrowLeftOutlined className='text-white ml-2 font-bold' onClick={exitFullScreen} />
                     <span className='ml-2 text-white font-bold'>{gamedetails.title}</span>
                </div>
                <div id="gamebar" className='h-10 w-full absolute bottom-0 z-10 bg-[#001529]'>

                          <h1 className='float-left ml-4 text-white text-lg font-semibold mt-1 xs:hidden sm:hidden xl:block'>{gamedetails.title}</h1>
                                
                          <FullscreenOutlined  className='float-right mr-4 text-white text-xl mt-2' onClick={enterFullScreen}/>
                          <span className='float-right mr-4 text-gray-500 text-lg mt-[3px]' >|</span>

                          

                          <Popover
                            content= {<div className='inline-block'><p className='text-white'>You need to login to comment.</p><Button type="primary" shape="round" size="default" className='float-right mt-3 bg-raven-pink' onClick={() => setOpenDrawer(true)}>Login</Button></div>}
                            trigger="click"
                            placement="topRight"
                            style = {{display : localStorage.getItem('token') ? 'none' : 'block'}}
                            open={localStorage.getItem('token') ? false : showfeedbackpopup}
                            onOpenChange={handlefeedbackChange}
                          >  
                          <span className='float-right mr-4 text-md cursor-pointer mt-2' style = {{color : 'white'}} onClick={sendFeedback}><CommentOutlined /> Comment</span>
                          </Popover>

                          <span className='float-right mr-4 text-gray-500 text-lg mt-[3px]' >|</span>

                          

                        

                          <Popover
                            content= {<div className='inline-block'><p className='text-white'>You need to login to save a game for later.</p><Button type="primary" shape="round" size="default" className='float-right mt-3 bg-raven-pink' onClick={() => setOpenDrawer(true)}>Login</Button></div>}
                            trigger="click"
                            placement="topRight"
                            style = {{display : localStorage.getItem('token') ? 'none' : 'block'}}
                            open={localStorage.getItem('token') ? false : showplaylaterpopup}
                            onOpenChange={handleplaylaterChange}
                          >
                          <span className='float-right mr-4 text-md cursor-pointer mt-2' style = {{color : play_later ? 'fuchsia' : 'white'}} onClick={playGameLater}><PlusOutlined /> <span className='xs:hidden'>Save</span></span>
                          </Popover>

                         

                          <span className='float-right mr-4 text-gray-500 text-lg mt-[3px]' >|</span>
                          <span className='float-right mr-4 text-md mt-2' style = {{color :  sentiment == "dislike" ? 'fuchsia' : 'white'}} >{dislike_count}</span>
                          <Popover
                            content= {<div className='inline-block'><p className='text-white'>You need to login to dislike a game.</p><Button type="primary" shape="round" size="default" className='float-right mt-3 bg-raven-pink' onClick={() => setOpenDrawer(true)}>Login</Button></div>}
                            trigger="click"
                            placement="topRight"
                            open={localStorage.getItem('token') ? false : showdislikeloginpopup}
                            onOpenChange={handledisOpenChange}
                          >
                          <DislikeOutlined  className='float-right mr-2 text-xl mt-2' style = {{color : sentiment == "dislike"  ? 'fuchsia' : 'white'}} onClick={dislikeGame}/>
                          </Popover>
                          <span className='float-right mr-4 text-md mt-2' style = {{color : sentiment == "like" ? 'fuchsia' : 'white'}} >{like_count}</span>

                          <Popover
                            content= {<div className='inline-block'><p className='text-white'>You need to login to like a game.</p><Button type="primary" shape="round" size="default" className='float-right mt-3 bg-raven-pink' onClick={() => setOpenDrawer(true)}>Login</Button></div>}
                            trigger="click"
                            placement="topRight"
                            open={localStorage.getItem('token') ? false : showlikeloginpopup}
                            onOpenChange={handleOpenChange}
                          >
                          <LikeOutlined  className='float-right mr-2 text-xl mt-2' style = {{color : sentiment == "like" ? 'fuchsia' : 'white'}} onClick={likeGame}/>
                          </Popover>
                          
                          
                          
                          {/*
                          <Popover
                            content={<div className='bg-raven-blue-black min-w-[200px]'>
                                <h2 className='text-white font-sans font-semibold text-xl mt-1'>Controls</h2>
                                <p className='text-gray-300 mt-3 font-sans mb-2'>
                                <div dangerouslySetInnerHTML={{__html: gamedetails.controls}}></div>
                                </p>
                            </div>}
                            trigger="click"
                            placement="topRight"
                            open={showpopup}
                            onOpenChange={null}
                          >
                            <QuestionCircleOutlined className='float-right mr-4 text-white text-xl mt-2' onClick={togglePopup}/>
                          </Popover>
                          */}
                          
                          
                
                </div>

            
            </div>
            

            <div id = "playnowcover" className={"flex w-full h-full absolute top-0 left-0 items-center justify-center z-20 overflow-hidden"}
                
            >

              <img alt = {gamedetails.thumbnail} className='absoulte top:0 left:0 w-full h-full blur-lg ' style = {{backgroundImage: "url('" + process.env.REACT_APP_MEDIA_URL + gamedetails.thumbnail +"')" , backgroundSize: 'cover',
                backgroundPosition: 'center'}} />
              <div className='absolute flex z-50 bg-gray-800/[.8]  top:0 left:0 w-full h-full items-center justify-center block'>
                <div>
                <h3 className='text-white text-2xl w-full text-center font-semibold'>{gamedetails.title}</h3>
              <Button type="primary" shape="round" size="default" onClick = {startGame} className=' bg-raven-pink h-12 text-lg block items-center mt-5 m-auto' >
              <div><span>Play Now </span><PlayCircleOutlined className='ml-1'/></div>
              
            </Button>
            </div>
            </div>
       
            </div>




            
                    
          </div>


           <div style = {{margin : 'auto' , marginTop : '10px' , width : 'fit-content'}} className='xs:hidden sm:hidden md:hidden lg:block'>
            <AdsenseComponent  adSlot="6080536034" adClient="ca-pub-4208207038795686" width = "728px" height = "90px"/>
           </div> 
           <div style = {{margin : 'auto' , marginTop : '10px' , width : 'fit-content'}} className='xs:block sm:block md:block lg:hidden'>
            <AdsenseComponent  adSlot="3603057255" adClient="ca-pub-4208207038795686" width = "300px" height = "100px"/>
           </div> 
          
           
           <Games query= {"page=2&exclude=" + gamedetails.id +"&limit=12&category=" + gamedetails.category}  />
           

           <div className='w-full bg-raven-blue-black p-4 mt-4 rounded-xl'>
                 <h1 className='text-white font-sans font-semibold text-xl'>{gamedetails.title}</h1>

                 <Share game_name={gamedetails.title} url={window.location.href} />

                 
                 <div className='text-gray-300 mt-5 font-sans'>
                  {gamedetails.description}
                 </div>
                 {/*
                 <h2 className='text-white font-sans font-semibold text-xl mt-5'>Controls</h2>
                 <p className='text-gray-300 mt-5 font-sans'>
                 <div dangerouslySetInnerHTML={{__html: gamedetails.controls}}></div>
                 </p>
                  */}

                  <div className='mt-10'>
                  {
                      gamedetails.tags.split(',').map(
                        t => (

                          (t != '') && <Tag color="#108ee9" className='text-[14px] mt-2 cursor-pointer' onClick={() => navigate(`/t/${t}`)}>{t}</Tag>

                        )
                      )
                    }
                 

                  </div>
                  
              
               </div>

              <div className='mt-[10px]'>
               <Games query= {"page=3&exclude=" + gamedetails.id +"&limit=12&category=" + gamedetails.category}  />
               </div>


           </Col>

           <Col xs = {{span : 0 , offset : 0}} sm = {{span : 0 , offset : 0}} md = {{span : 0 , offset : 0}} lg = {{span : 0 , offset : 0}} xl = {{span : 4 , offset : 0}} xxl = {{span : 6 , offset : 0}}>
             
              
              <div style = {{ marginLeft : '10px' , marginTop : '0px'}}>           
                <AdsenseComponent  adSlot="8016700167" adClient="ca-pub-4208207038795686" width = "160px" height = "600px"/>
              </div> 

              <div className='lg:max-w-[140px] 2xl:max-w-[300px] ml-[20px] mt-[250px]'>
                <Games query= {"page=1&exclude=" + gamedetails.id +"&limit=10&category=" + gamedetails.category}  />
              </div>
              
            </Col>
         
        </Row>

       

        </div>
        ) }


        </div>
         )
        }
      
    </div>

    );
  }
  
  export default GameDetails;