import { apiClient } from './api-config';
export const postEmail = async (email) => {
    try {
      const response = await apiClient.post('maillist/add/', {email : email});
      return response.data;
    } catch (error) {
      //console.error('Error submitting emails:', error);
      return null;
    }
  };

  export const joinTournament = async (tournament) => {
    try {
      const response = await apiClient.post('tournaments/join/', {tournament : tournament});
      return [true, response.data.message];
    } catch (error) {
      //console.error('Error submitting emails:', error);
      return [false, error.response.data.message];
    }
  };


  export const play = async (tournament) => {
    try {
      const response = await apiClient.post('tournaments/play/', {tournament : tournament});
      return response.data.sess;
    } catch (error) {
      return null;
    }
  };

  export const fetchTournamentDetails = async (tname) => {
    try {
      const response = await apiClient.get('/tournaments/detail/' + tname + '/');
      return response.data;
    } catch (error) {
      console.error('Error fetching tournament details:', error);
      return null;
    }
  };

  export const getTournamentList = async (tname) => {
    try {
      const response = await apiClient.get('/tournaments/list/');
      return response.data;
    } catch (error) {
      console.error('Error fetching tournament list:', error);
      return null;
    }
  };

  


  export const fetchLeaderboard = async (tid , query) => {
    try {
      const response = await apiClient.get('/tournaments/leaderboard/' + tid + '/?' + query);
      return response.data;
    } catch (error) {
      return null;
    }
  };




  