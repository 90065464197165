
import { Col, Row , Avatar , Card , Divider , Progress , notification} from 'antd';

import { UserOutlined , EditOutlined , CopyOutlined } from '@ant-design/icons';
import {logout} from '../services/auth-service';

import {profile} from '../services/profile-service';
import {useEffect, useState , useRef} from 'react';

function Profile(props) {
    
    const profile_page = JSON.parse(localStorage.getItem('profile'));
    const [profiledetails, setProfileDetails] = useState({});  

    const logoutUser = async() => {
      let result = await logout();
      props.setUser(false);
      props.setOpenDrawer(false);
      props.cngDrawerComp('login');
      window.location.reload();


    }

    const copyLink = () => {
      navigator.clipboard.writeText(`https://www.ravenarena.com/?refer=${profile_page.referral_code}`);

      notification.info({
        message: `Link Copied !`,
        description: '',
        placement : 'bottomRight',
        duration : '2',
      });

    }

    const editProfile = () => {
      props.cngDrawerComp('edit_profile');
    }

    useEffect( () => {

      const getAccountDetails = async() => {
        let p =  await profile();
        setProfileDetails(p[1].account_details);

      }
      getAccountDetails();
      
    }, []); 
    

    return (
      <div>

            <Avatar size={54} icon={<UserOutlined />} className='bg-raven-pink mx-auto block' />
           <span className='font-semibold text-white text-2xl w-full block text-center mt-5'>{profile_page.username} <EditOutlined className='text-white text-lg cursor-pointer' onClick = {editProfile}/></span>
           <span className=' text-gray-300 text-base w-full block text-center'>{profile_page.email}</span>
           
           <Divider className='bg-raven-mid-blue'/>
          
           

           <span className='block w-full text-center text-xl text-white'>You are <span className='font-semibold text-green-400  pt-2'>{profiledetails.level}</span></span>

            <div className='m-auto block w-fit mt-3 mb-2'>

            
              <Progress percent={100 * profiledetails.points / profiledetails.target_point } strokeColor="#c65298" type="circle" format={(percent) => `${percent} %`} />
           
            </div>

           <span className='text-gray-300 mt-2 text-center block'>{profiledetails.target_point - profiledetails.points} points required for next level</span>
            
           <div className = 'bg-raven-mid-blue border-0 mt-4 text-white p-3 rounded-lg'>

          <div className='ml-2 mr-2'>
           <Row>
            <Col span={18}><span className='text-gray-300'>Play a Game for atleast 1 minute</span></Col>
            <Col span={6}><span className='text-raven-pink'>+2pts</span></Col>
           </Row>
           <Row className='mt-2'>
            <Col span={18}><span className='text-gray-300'>Like a Game</span></Col>
            <Col span={6}><span className='text-raven-pink'>+1pts</span></Col>
           </Row>
           <Row className='mt-2'>
            <Col span={18}><span className='text-gray-300'>Send a Feedback</span></Col>
            <Col span={6}><span className='text-raven-pink'>+3pts</span></Col>
           </Row>
           <Row className='mt-2'>
            <Col span={18}><span className='text-gray-300'>Read a Blog</span></Col>
            <Col span={6}><span className='text-raven-pink'>+2pt</span></Col>
           </Row>
           <Row className='mt-2'>
            <Col span={18}><span className='text-gray-300'>Refer Friends to Signup</span></Col>
            <Col span={6}><span className='text-raven-pink'>+10pts</span></Col>
           </Row>
           
           </div>
            


               
           </div>





           <Divider className='bg-raven-mid-blue'/> 

           <span className='font-semibold text-raven-pink text-xl w-full block text-center mt-5'>Refer Your Friends</span>

           <span className='mt-4 block w-full text-center text-gray-200'>Bring your friends into the action and grow our player community 😊. </span>

           <div className = 'bg-raven-mid-blue border-0 mt-4 text-white p-3 rounded-lg'>
             https://www.ravenarena.com/?refer={profile_page.referral_code}
             <CopyOutlined className='ml-2' onClick={copyLink}/>
           </div> 
          
           
            <span className='font-semibold float-left block text-raven-pink mt-5 w-full text-center text-base cursor-pointer pb-5' onClick={logoutUser}>Logout</span>
            
                



      </div>
    );
  }
  
  export default Profile;