import { Image } from 'antd';
import logo from '../assets/Logo.png';
import { useState } from 'react';
import { Button, Modal , Rate , Form,Input } from 'antd';
import {sendGeneralFeedback, checkGeneralFeedback} from '../services/feedback-service';
import { useEffect } from 'react';


function FeedbackModal(props) {

    const [isModalOpen, setIsModalOpen] = useState(props.show);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    
    useEffect( () => {
        
        /*const getGames = async() => {
          let gameslst =  await sendGeneralFeedback("limit=60&category="+cat_name);
          setGames(gameslst);
          setGamesLoaded(true);
        }
    
        getGames();*/
       
        
    }, []); 


 


    const handleOk = () => {

        form
        .validateFields()
        .then((values) => {
            console.log('Feedback Submitted:', values);
            sendGeneralFeedback(values.rating , values.comment);
            form.resetFields(); // Reset the form after submission
            setIsModalOpen(false);
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });


        
    };
    
    const handleCancel = () => {
        sendGeneralFeedback(3 , "No Comment");
        setIsModalOpen(false);
    };
   
    return (
       <div>
            <Modal 
             
              title="" 
              open={isModalOpen} 
              onOk={handleOk} 
              onCancel={handleCancel} 
              closable = {false} 
              centered = {true} 
              maskClosable={false}

              okButtonProps={{ type: "primary", style: { backgroundColor: '#1DA57A', borderColor: '#1DA57A' } }}
              cancelButtonProps={{ type: "primary", style: { backgroundColor: '#f55d42', borderColor: '#f55d42' }  }}
              okText="Submit Feedback"
              cancelText="Dismiss"
            
            >

            <Form
                form={form}
                
            >

                <h2 className='text-white text-2xl text-center mt-5'>We Value Your Feedback!</h2>


                <p className='text-white text-center mt-5 mb-5'>Did our website meet your expectations? We're always looking to improve and would love to hear your thoughts and suggestions.</p>
               

               <Form.Item name="rating" rules={[{ required: true, message: 'Please rate your experience!' }]}>
                    <Rate className='justify-center flex'/>
                </Form.Item>

                <Form.Item  name="comment" rules={[{ required: true, message: 'Please write your comment!' } , { min: 5, message: 'Comment must have atleast 5 characters.' }]}>
                    <TextArea rows={6} placeholder="Add your comment here" className='h-12 rounded-3xl bg-raven-mid-blue mt-3'  />
                </Form.Item>

           

            </Form>

            
            </Modal>
       </div>
 
     );
   }
   
export default FeedbackModal;