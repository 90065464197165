import axios from 'axios';
import { refreshToken } from './auth-service';



// Set up the base URL for all API requests
export const apiClient = axios.create({
  baseURL:  process.env.REACT_APP_API_BASE_URL,
  // You can add more default settings here
  headers: {
    'Content-Type': 'application/json',
    // Add other global headers if necessary
  },
});

export const apiClient2 = axios.create({
  baseURL:  process.env.REACT_APP_API_BASE_URL,
  // You can add more default settings here
  headers: {
    'Content-Type': 'application/json',
    // Add other global headers if necessary
  },
});


apiClient.interceptors.request.use(
  async config => {
      const token = localStorage.getItem("token"); // Method to get access token from authService
      if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
  },
  error => {
      console.log('request err');
      return Promise.reject(error);
  }
);

apiClient2.interceptors.request.use(
  async config => {
      const token = localStorage.getItem("token"); // Method to get access token from authService
      if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
  },
  error => {
      console.log('request err');
      return Promise.reject(error);
  }
);


apiClient.interceptors.response.use(
  response => response,
  async error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {


          originalRequest._retry = true;

          try {
              let result = await refreshToken(localStorage.getItem('refresh'));
              console.log(result);

              if(result[0]){
                const newAccessToken = localStorage.getItem('token');
                //console.log(`refreshing access token ${newAccessToken}`)
                if (newAccessToken) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                    console.log('response call original');
                    return apiClient(originalRequest);
                }
              }
              else{
                console.log('refresh error');
                window.location.reload();
                return Promise.reject(result[1]); // Prevent further retries
                
              }
             
          } catch (refreshError) {
             
              if (refreshError.response && (refreshError.response.status === 401 || refreshError.response.data.error === "Refresh token expired")) {
                // Redirect to login or handle session expiration
                localStorage.removeItem('refresh')
                localStorage.removeItem('token')
                //window.location.reload();
                return Promise.reject(refreshError); // Prevent further retries
              }
              return Promise.reject(refreshError); // Failed to refresh token
          }
      }

      return Promise.reject(error); // Any other errors
  }
);


