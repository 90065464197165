import {Menu} from 'antd';
import {NavLink} from 'react-router-dom';
import {CasualIcon , HomeIcon ,PlayLaterIcon , LikedIcon , DrivingIcon,BattleIcon , ShooterIcon , PuzzleIcon , SportsIcon , AdventureIcon , AgilityIcon , SimulationIcon , DressupIcon} from './icons';


function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  function wrap_link(component , link){
     return (<NavLink to={link} style={({ isActive }) => {
        return isActive ? { color: "#c65298"} : {};
        }}>{component}</NavLink>);
  }

  const side_menus = [
    getItem(wrap_link('Home' , "/"), 'home' ,  wrap_link(<HomeIcon /> , "/")),
    getItem(wrap_link('Liked Games' , "/likes"), 'likes' ,  wrap_link(<LikedIcon /> , "/likes")),
    getItem(wrap_link('Play Later' , "/playlater"), 'playlater' ,  wrap_link(<PlayLaterIcon /> , "/playlater")),
    {
      type: 'divider',
    },
    getItem(wrap_link('Casual' , "/cat/casual") , 'casual' ,  wrap_link(<CasualIcon /> , "/cat/casual")),
    getItem(wrap_link('Puzzle' , "/cat/puzzle") , 'puzzle' ,  wrap_link(<PuzzleIcon /> , "/cat/puzzle")),
    getItem(wrap_link('Battle'  , "/cat/battle"), 'battle' , wrap_link( <BattleIcon /> , "/cat/battle")),
    getItem(wrap_link('Sports' , "/cat/sports") , 'sports' ,  wrap_link(<SportsIcon /> , "/cat/sports")),
    getItem(wrap_link('Agility' , "/cat/agility") , 'agility' ,  wrap_link(<AgilityIcon /> , "/cat/agility")),
    getItem(wrap_link('Adventure' , "/cat/adventure") , 'adventure' ,  wrap_link(<AdventureIcon /> , "/cat/adventure")),
    getItem(wrap_link('Driving' , "/cat/driving") , 'driving' ,  wrap_link(<DrivingIcon /> , "/cat/driving")),
    getItem(wrap_link('Shooter' , "/cat/shooter") , 'shooter' ,  wrap_link(<ShooterIcon /> , "/cat/shooter")),
    getItem(wrap_link('Simulation' , "/cat/simulation") , 'simulation' ,  wrap_link(<SimulationIcon /> , "/cat/simulation")),
    getItem(wrap_link('Dress up'  , "/cat/dressup") , 'dressup' ,  wrap_link(<DressupIcon /> , "/cat/dressup")),
  ];



  function GameSideMenu(props){

    
   

     return (
        
        <Menu
                
                mode="inline"
                theme="dark"
                inlineCollapsed={props.menucollapse}
                items={side_menus}
                className='mt-[-16px] min-h-[calc(100vh-65px)] float-left'
                onMouseEnter = {props.onMouseEnter}
                onMouseLeave = {props.onMouseLeave}
                onClick={props.onClick}

              />
         
          
     )
  }

  export default GameSideMenu;