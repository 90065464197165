

import { SocialIcon } from 'react-social-icons';
import {notification , Tooltip} from 'antd';
import {useEffect} from 'react';
import {LinkOutlined} from '@ant-design/icons';

function Share(props) {


    const visit_page = (type) => {

      const y = window.top.outerHeight / 2 + window.top.screenY - (400 / 2);
      const x = window.top.outerWidth / 2 + window.top.screenX - (600 / 2);
  
      var shareUrl = "";
      var title = "Check out this game I'm playing";

      if(props.type == "tournament"){
        title = "Check out this tournament I'm in";
      }

     
      if(type == "x"){
        shareUrl = `https://www.twitter.com/share?url=${props.url}&text=${title} - ${props.game_name}&via=ravenarena24&hashtags=ravenarena&related=ravenarena24`
      }

      else if(type == "facebook"){
        shareUrl = `https://www.facebook.com/share.php?u=${props.url}`

      }

      else if(type == "whatsapp"){
        shareUrl = `https://web.whatsapp.com/send?text=${title} -${props.game_name} ${props.url}`

      }
      else if(type == "reddit"){
        shareUrl = `https://www.reddit.com/submit?url=${props.url}&title=${title} - ${props.game_name}`
      }

      var popup = window.open(shareUrl, 'shareWindow', `width=600,height=400,top=${y}, left=${x}`);
    }

    const copyLink = () => {
      navigator.clipboard.writeText(window.location.href);

      notification.info({
        message: `Link Copied !`,
        description: '',
        placement : 'bottomRight',
        duration : '2',
      });

    }



    useEffect( () => {
         
      }, []);

    return (
        <div>
          <div className='bg-[#001529] w-fit xs:pl-[10px] xs:pr-[10px] sm:pl-[10px] sm:pr-[10px] md:pl-[30px] md:pr-[30px] pt-[10px] pb-[10px] rounded-xl xs:mx-auto sm:mx-auto md:mx-0 mt-3'>
            <h2 className='text-white mb-2 font-[10px] xs:text-center sm:text-center md:text-left'>Share this game</h2>

            <div className='flex'>
            <Tooltip title="Twitter" placement="bottom"><SocialIcon network="x" onClick = {() => visit_page("x")} /></Tooltip>
            <Tooltip title="Facebook" placement="bottom"><SocialIcon network="facebook" onClick = {() => visit_page("facebook")} className='ml-3' /></Tooltip>
            <Tooltip title="Whatsapp" placement="bottom"><SocialIcon network="whatsapp" onClick = {() => visit_page("whatsapp")} className='ml-3'/></Tooltip>
            <Tooltip title="Reddit" placement="bottom"><SocialIcon network="reddit" onClick = {() => visit_page("reddit")} className='ml-3' /></Tooltip>
            <Tooltip title="Link" placement="bottom"><div className='w-[50px] h-[50px] rounded-3xl bg-raven-pink ml-3 block justify-center cursor-pointer' onClick={copyLink}>
                <LinkOutlined className='mt-[13px] ml-[13px] text-white text-2xl' />
            </div></Tooltip>
            </div>

          </div>
        </div>

    );


}


export default Share