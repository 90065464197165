
import { Image , Card , Button} from 'antd';
import CardImage from '../assets/Tournamentsbackground.png';
import Coin from '../assets/Coin.png';
import { UserOutlined , TrophyFilled , CalendarOutlined } from '@ant-design/icons';

function TournamentCard(props) {

  
    return (
      <div 
      className='mr-3 mt-3 bg-raven-mid-blue border-0 rounded-[4px] relative cursor-pointer' onClick={props.onClick}
      >
         <img alt={props.tournament} className = "rounded-[4px] z-0" height = {80} src={props.image}  />
      </div>

    );
  }
  
  export default TournamentCard;