
import {useEffect, useState , useRef} from 'react';
import TournamentCard from '../components/tournamentcard-component';
import {useNavigate} from 'react-router-dom';
import { Row,Col} from 'antd';
import '../App.css';



function Tournaments(props){
   
   
    const navigate = useNavigate();
    const [trns, setTournaments] = useState([]);  

    

    useEffect( () => {
        setTournaments(props.list);
        
      }, []); 



    const onTClick = (b) => {
        navigate('/tournament/' + b);
    }



    return (
       
                  <div>
                   <Row className = "mb-5">
                        {
                            trns.map(
                                t => (
                                    <Col xs={24} sm = {24} md= {12} lg ={12} xl={8} xxl={8}>
                                        <TournamentCard image = {process.env.REACT_APP_MEDIA_URL + t.thumbnail} tournament = {t.name}  entry = {t.entry_fee} date_diff = {t.diff} game = {t.game_name} top_reward = {t.first_place_winnings} onClick = {() => onTClick(t.name)}/>
                                    </Col>
                                )
                            )
                        }
                  </Row>
                 
        </div>
      );
}


export default Tournaments;


    