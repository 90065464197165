
import { Row,Col,Result,Button } from 'antd';
import Games from '../components/gamelist-component';
import Loading from '../components/loading-component';
import {emailActivate} from '../services/auth-service';
import {useEffect, useState} from 'react';
import NewPassword from '../components/newpassword-component';
import { useParams , useSearchParams } from 'react-router-dom';

function NewPasswordPage() {

  let [params , setSearchParams] = useSearchParams();

  useEffect( () => {

  
    
  }, []); 

   
    return (
      <div>
        <div className='bg-[#221f38] block m-auto justify-center rounded-lg pl-10 pr-10 pt-5 pb-10 w-[350px]'>
              <NewPassword  uid={params.get('uid')}  token={params.get('token')} />
        </div>
      </div>
    );
  }
  
  export default NewPasswordPage;