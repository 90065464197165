import React, { useEffect } from 'react';

const AdsenseComponent = ({ adClient, adSlot, adFormat , width , height }) => {
  useEffect(() => {

    try{
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    }
    catch(error){

    }
   

  }, []);

  return (
    <ins 
         className="adsbygoogle"
         style={{ display: 'block' , width: width , height : height }}
         data-ad-client={adClient}
         data-ad-slot={adSlot}
         data-adtest="on"
    ></ins>
  );
};

export default AdsenseComponent;