
import trophy from '../assets/trophy.webp';
import badge from '../assets/badge.png';
import money from '../assets/money.png';
import group from '../assets/group.png';
import select from '../assets/select.png';
import {Row , Col ,  Input , Button , message} from 'antd';
import {postEmail} from '../services/tournament-service';
import React, { useState, useEffect } from "react";


function TournamentComingSoon() {


    var email_to_send = "";

    const submitEmail = () => {
        const send = async() => {
            let result = await postEmail(email_to_send);
            if(result == null){
                message.error("Unable to submit email. Please enter a correct email and send again!");
               
            }
            else{

                message.success("Thank you! We will notify you soon.");
                
            }
        }
        send();

       

        
    }

    

    

    const onChange = (e) => {
        email_to_send = e.target.value;
    }

    return (
        <div className="lg:mt-[-20px] sm:mt-[-85px] xs:mt-[-85px]  bg-cover bg-center bg-[url('assets/torunament-cmingsoon-bg.webp')] w-screen " style = {{height : "calc(100vh - 60px)"}}>
           
            <img src={trophy} className='m-auto lg:pt-16 md:pt-20 sm:pt-20 xs:pt-20 md:w-[300px] sm:w-[200px] xs:w-[150px]'/>
            <h3 className = "text-[#43EEEA] text-center mt-4 md:text-2xl sm:text-xl xs:text-xl font-sans">Tournaments</h3>
            <h1 className = "text-[#43EEEA] text-center mt-2 md:text-4xl sm:text-2xl xs:text-2xl font-bold font-sans">COMING SOON</h1>
           
           
            <p className = 'm-auto text-gray-400 mt-4 md:w-[600px] sm:w-[300px] xs:w-[300px] text-center'>Discover the thrill of combining entertainment with income on our gaming platform! Dive into a diverse world of games where your skills can lead to real earnings.</p>
           
             <h3 className = "text-[#43EEEA] text-center mt-4 md:text-2xl sm:text-xl xs:text-xl font-sans">How to Play</h3>
           
            <Row className='m-auto mt-6 md:w-[600px] sm:w-[400px] xs:w-[360px]'>
                <Col span = {6}>
                    <div>
                        <img src={select} width = {50} className='m-auto'/>
                        <span className = 'text-center text-gray-400 w-full block mt-2'>Select a Tournament</span>
                    </div>
                </Col>
                <Col span = {6}>
                    <div>
                        <img src={money} width = {50} className='m-auto'/>
                        <span className = 'text-center text-gray-400 w-full block mt-2'>Enter for FREE or pay a small entry fee</span>
                    </div>
                </Col>
                <Col span = {6}>
                    <div>
                        <img src={group} width = {50} className='m-auto'/>
                        <span className = 'text-center text-gray-400 w-full block mt-2'>Compete with other players.</span>
                    </div>
                </Col>
                <Col span = {6}>
                <div>
                        <img src={badge} width = {50} className='m-auto'/>
                        <span className = 'text-center text-gray-400 w-full block mt-2'>Win upto $1000</span>
                    </div>
                </Col>
            </Row>

            <div className='m-auto mt-6 md:w-[600px] sm:w-[400px] xs:w-[360px] bg-[#009540] p-5 rounded-lg'>
                  <h3 className='text-white pb-2 font-semibold'>Please add your email to be notified.</h3>
                  <input className='w-[72%] h-[30px] rounded-lg pl-2' onChange={onChange}/><Button className='ml-2 w-[24%] bg-white text-black hover:bg-green-300 hover:text-white'  onClick={submitEmail}>Submit</Button>
            </div>

        </div>

    )

}


export default TournamentComingSoon;