

import { Input , Select , Button , Form, Alert} from 'antd';
import {getDepositAddress , getBalance} from '../services/wallet-service';
import React, {useEffect , useState} from 'react';

function DepositPage(props) {  
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errmsg , setErrMsg] = useState(null);
  const [balance , setBalance] = useState("-.--");

  useEffect( () => {

    const getAccountBalance = async() => {
      let p =  await getBalance();
      if(p != null){
        setBalance(p);
      }
    }
    getAccountBalance();
    const intervalId = setInterval(getAccountBalance, 30000);


    return () => {
      // This function will be called when the component is unmounted (destroyed)

      if(intervalId != null){
        clearInterval(intervalId);
      }
    };
    
  }, []); 

  const onFinish = (values) => {

    const deposit = async() => {
      setLoading(true);
      let address = await getDepositAddress();
      if(address != null){
        setLoading(false);
        localStorage.setItem('deposit_address' , address);
        props.cngDrawerComp('deposit_address');
      }
      else{
        setLoading(false);
        setErrMsg("Unable to generate Deposit Address.");
      }
    }
    deposit();


  }


    return (
      <div>
         <span className='font-semibold text-raven-pink text-2xl w-full block text-center mt-10'>Deposit</span>
          <div className = "bg-raven-mid-blue w-full p-5 mt-5 rounded-xl">
           <span className=' text-gray-300 text-base w-full block'>Balance</span>
          <span className='font-semibold text-white text-2xl w-full block mt-2'>${balance}</span>
          </div>

          <span className=' text-gray-300 text-base w-full block mt-10'>Deposit</span>

          <Alert
                message={errmsg}
                type="error"
                className='mt-3 bg-red-300 border-red-300 text-red-800 rounded-3l'
                style = {{display : errmsg ? "block" : "none"}}
              />


          <Form

            className='mt-1'
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={null}
            autoComplete="off"
          >
            
         
          <Form.Item name="coin" rules={[{required: true , message: 'Please select a currency!' }]}>
          <Select placeholder="Currency" className='h-12 rounded-3xl bg-raven-mid-blue w-full'  options={[
                        { value: 'USDT', label: 'USDT'},
                    ]} />
          </Form.Item>
          <Form.Item name="network" rules={[{required: true , message: 'Please select a network!' }]}>
          <Select placeholder="Network" className='h-12 rounded-3xl bg-raven-mid-blue w-full'  options={[
                        { value: 'BSC', label: 'BEP20' },
                    ]} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" shape="round"  htmlType="submit" size="default"  className='bg-raven-pink mt-1 h-12 w-full text-lg' loading = {loading} >
              Deposit
            </Button>
            </Form.Item>

            </Form>
            <span className='text-gray-300 cursor-pointer mt-3 m-auto block w-fit text-[17px] font-semibold' onClick={() => props.cngDrawerComp('wallet')}>
              Cancel
            </span>
      </div>
    );
  }
  
  export default DepositPage;