

import { Col, Row , Image , Button , Form, List , Avatar } from 'antd';
import React, {useEffect,useState} from 'react';
import {getBalance} from '../services/wallet-service';


function Wallet(props) {

  const [balance , setBalance] = useState("-.--");


  useEffect( () => {

    const getAccountBalance = async() => {
      let p =  await getBalance();
      if(p != null){
        setBalance(p);
      }
    }
    getAccountBalance();
    const intervalId = setInterval(getAccountBalance, 30000);


    return () => {
      // This function will be called when the component is unmounted (destroyed)

      if(intervalId != null){
        clearInterval(intervalId);
      }
    };
    
  }, []); 


  const openWithdrawPage = () => {
    props.cngDrawerComp('withdraw');
  }

  const openDepositPage = () => {
    props.cngDrawerComp('deposit');
  }

    const data = [
        {
          type: 'Deposit',
          value : '$12.00'
        },
        {
            type: 'Withdraw',
            value : '$12.00'
          },
          {
            type: 'Deposit',
            value : '$12.00'
          },
          {
            type: 'Deposit',
            value : '$12.00'
          },
      ];

    return (
      <div>
         <span className='font-semibold text-raven-pink text-2xl w-full block text-center mt-10'>Your Wallet</span>
          <div className = "bg-raven-mid-blue w-full p-5 mt-5 rounded-xl">
           <span className=' text-gray-300 text-base w-full block'>Balance</span>
           <span className='font-semibold text-white text-2xl w-full block mt-2'>${balance}</span>
          </div>
          <Row className = 'mt-3'>
            <Col span={12}>
                <Button type="primary" shape="round" size="default"  className='bg-raven-pink mt-5 h-10 w-full text-lg mr-2' onClick={openDepositPage}>
                    Deposit
                </Button>
            </Col>
            <Col span={12}>
                <Button type="primary" shape="round" size="default"  className= 'bg-raven-mid-blue mt-5 h-10 w-full ml-2 text-lg' onClick={openWithdrawPage}>
                    Withdraw
                </Button>
            </Col>
          </Row>

          {/*

          <span className=' text-gray-300 text-base w-full block mt-10'>Transaction History</span>

          <List
            className='mt-5'
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
            <List.Item>
                <List.Item.Meta
                avatar={<Avatar size={32} icon={item.type === "Deposit" ? <RightOutlined className='text-green-400'/> : <LeftOutlined className='text-red-400' />} />} 
                title={<div><span className='text-white'>{item.type}</span><span className='float-right text-gray-400 '>12.01.2024</span></div>}
                description={<span className='text-raven-light-blue'>{item.value}</span>}
                />
            </List.Item>
            )}
           />

            */}

          
      </div>
    );
  }
  
  export default Wallet;