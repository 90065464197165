
import { Image } from 'antd';
import logo from '../assets/Logo.png';
import { useRef,useState,useEffect } from 'react';

function GameCard(props) {
   
   const [showtitle , setShowTitle] = useState('none');
   const parentRef = useRef(null);
   const [fontsize, setFontSize] = useState('12px');

   useEffect( () => {

    if (parentRef.current) {
      let w = parentRef.current.getBoundingClientRect().width;
      if(w > 150){
        setFontSize('12px')
      }
      else{
        setFontSize('10px')
      }
    }
      

     
   
    
    }, []); 
  
    return (
      <div ref={parentRef} className='bg-[#222] w-[calc(100% - 8px)] aspect-[720/444] mr-2 mt-2 rounded-2xl hover:border-amber-400 hover:border-[3px] hover:border-solid'  onClick={props.onClick}
      onMouseEnter={() => setShowTitle('block')}
      onMouseLeave={() => setShowTitle('none')}
      
      >
      
      <img alt = {props.image} src = {props.image} height = {444}
      className='rounded-xl w-full bg-cover block' 
    />
      
      <div className='absolute bottom-0 left-0  bg-raven-blue-black/80 w-[95%] p-[30px] rounded-b-2xl blur-[15px]' style = {{display : showtitle}}>
      </div>
      <span className='absolute bottom-3 left-4 text-white font-semibold right-3 overflow-hidden' style = {{display : showtitle , fontSize : fontsize}}>{props.title}</span>
      
    </div>

    );
  }
  
  export default GameCard;