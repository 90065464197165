
import {Layout , Image , Button , Drawer , Row , Col , Input , Menu ,  Avatar} from 'antd';
import React, { useState , useEffect , createContext , useContext } from 'react';
import { HomeOutlined , MenuOutlined , UserOutlined } from '@ant-design/icons';
import {getBalance} from '../services/wallet-service';

import { Outlet } from "react-router-dom"


import logo from '../assets/Logo.png';
import Login from '../pages/login-page';
import Register from '../pages/register-page';
import ForgotPassword from '../pages/forgot-password-page';
import Profile from '../pages/profile-page';
import EditProfile from '../pages/editprofile-page';
import WithdrawPage from '../pages/withdraw-page';
import DepositPage from '../pages/deposit-page';
import FeedBack from '../pages/feedback-page';
import DepositAddressPage from '../pages/deposit-address-page';





import Wallet from '../pages/wallet-page';
import Withdraw from '../pages/withdraw-page';
import GamesPage from '../pages/gameslist-page';
import Tournaments from '../components/tournamentlist-component';
import coin from '../assets/Coin.png';
import Loading from '../components/loading-component';
import {Routes, Route, useNavigate ,NavLink} from 'react-router-dom';
import GameSideMenu from '../components/games-side-menu-component';
import { useParams , useSearchParams } from 'react-router-dom';
import {notification} from 'antd';

import '../antd-overwrite.css';

const { Header, Content, Footer } = Layout;










function MainLayout() {

  const [opendrawer, setOpenDrawer] = useState(false);
  const [menucollapse, setMenucollapse] = useState(true);
  const [drawercomp , setDrawerComp] = useState('login');
  let [params , setSearchParams] = useSearchParams();
  const [user, setUser] = useState(false);
  const [balance , setBalance] = useState("-.--");



  
  
  
  const navigate = useNavigate();
  const { Search } = Input;

  const OutletContext = createContext();
  const useOutletContext = () => useContext(OutletContext);

  let intervalId = null;

  useEffect(() => {
    if(localStorage.getItem('token')){
      setDrawerComp('profile');
      setUser(true);

      const getAccountBalance = async() => {
        let p =  await getBalance();
        if(p != null){
          setBalance(p);
        }
      }
      getAccountBalance();
      intervalId = setInterval(getAccountBalance, 30000);



    }

    return () => {
      // This function will be called when the component is unmounted (destroyed)

      if(intervalId != null){
        clearInterval(intervalId);
      }
    };
}, []);


  const onSearch = (value, _e, info) => {
    navigate("/search?q="+value);
  }

  const onMenuClick = (i) => {
    if(i.key == "home"){
     navigate("/");
    }
    else if(i.key == "likes"){
      navigate("/likes");
    }
    else if(i.key == "playlater"){
      navigate("/playlater");
    }
    else{
     navigate("/cat/" + i.key);
    }
   
}

const onSideBarMouseEnter = () => {
  setMenucollapse(false);
  let wrapper = document.getElementById("sidemenuwrapper");
  wrapper.style.width = '200px';

}


const toggleMenu = () => {

 

 let wrapper = document.getElementById("sidemenuwrapper");
 if(menucollapse){
   wrapper.style.display = "block";
 }
 else{
   wrapper.style.display = "none";
 }


 setMenucollapse(!menucollapse);

}

const hideSideBar = () => {
  let wrapper = document.getElementById("sidemenuwrapper");
  if(window.innerWidth < 1000){
    wrapper.style.display = "none";
    setMenucollapse(true);
  }
  else{
    setMenucollapse(true);
    wrapper.style.width = '60px';
  }
}

const openProfile = () => {
  setDrawerComp('profile');
  setOpenDrawer(true);
}


const openWallet = () => {
  setDrawerComp('wallet');
  setOpenDrawer(true);
}


const showNotification = (title,desc) => {

  notification.info({
    message: title,
    description: desc,
    placement : 'topRight',
    duration : '5',
  });

}


const DrawerComponent = () => {
     if(drawercomp == 'login'){
        return (<Login cngDrawerComp = {setDrawerComp}  setUser = {setUser}  setOpenDrawer = {setOpenDrawer}/>)
     }
     else if (drawercomp == 'register'){
      return (<Register cngDrawerComp = {setDrawerComp}/>) 
     }
     else if (drawercomp == 'forgot_pass'){
      return (<ForgotPassword cngDrawerComp = {setDrawerComp}/>) 
     }
     else if (drawercomp == 'profile'){
      return (<Profile cngDrawerComp = {setDrawerComp}  setUser = {setUser}  setOpenDrawer = {setOpenDrawer}/>) 
     }
     else if (drawercomp == 'edit_profile'){
      return (<EditProfile cngDrawerComp = {setDrawerComp}  setUser = {setUser}  setOpenDrawer = {setOpenDrawer}/>) 
     }
     else if (drawercomp == 'wallet'){
      return (<Wallet cngDrawerComp = {setDrawerComp}  setUser = {setUser}  setOpenDrawer = {setOpenDrawer}/>) 
     }
     else if (drawercomp == 'withdraw'){
      return (<WithdrawPage cngDrawerComp = {setDrawerComp}  setUser = {setUser}  setOpenDrawer = {setOpenDrawer} notify = {showNotification}/>) 
     }
     else if (drawercomp == 'deposit'){
      return (<DepositPage cngDrawerComp = {setDrawerComp}  setUser = {setUser}  setOpenDrawer = {setOpenDrawer} />) 
     }
     else if (drawercomp == 'deposit_address'){
      return (<DepositAddressPage cngDrawerComp = {setDrawerComp}  setUser = {setUser}  setOpenDrawer = {setOpenDrawer} notify = {showNotification}/>) 
     }
     else if (drawercomp == 'feedback'){
      return (<FeedBack cngDrawerComp = {setDrawerComp}  setUser = {setUser}  setOpenDrawer = {setOpenDrawer}/>) 
     }   

     
}



  

 

  return (
    <div >
          <Layout className="min-h-screen  bg-[#0C0D14] pb-10">
              <Header className='h-auto pl-10 pr-10 sticky top-0 z-50'>
                <Row className='w-full'>
                <Col xs={7} sm = {13} md= {14} lg ={5} xl={3} xxl={4} >
                <MenuOutlined className='text-gray-400 text-2xl ml-[-20px] block mt-4 cursor-pointer float-left ' onClick={toggleMenu}/>
                <img preview = {false} alt="this is logo" src = {logo} className='xs:w-[70px] sm:w-[80px] md:w-[90px] block xs:mt-[20px] sm:mt-[20px] md:mt-[15px] xs:ml-[15px] sm:ml-[15px] lg:ml-[30px] bg-cover bg-center cursor-pointer ml-[20px]' onClick={() => navigate('/')}/>
                </Col>
                <Col xs={0} sm = {0} md= {0} lg ={0} xl={8} xxl={8} >
                <div className='block ml-10'>
                
                <NavLink to="/" className="font-semibold text-lg cursor-pointer text-white"  style={({ isActive }) => {
                                          return isActive ? { color: "#c65298" } : {};
                                          }}>Games</NavLink>
                
                <NavLink to="/tournaments" className="font-semibold ml-10 text-lg cursor-pointer text-white"  style={({ isActive }) => {
                                          return isActive ? { color: "#c65298" } : {};
                                          }}>Tournaments</NavLink>

                
                <NavLink to="/blogs" className="font-semibold text-lg ml-10 cursor-pointer text-white" style={({ isActive }) => {
                                          return isActive ? { color: "#c65298" } : {};
                                          }}>Blogs</NavLink>
                     
                  </div>
                </Col>

                <Col xs={0} sm = {0} md= {0} lg ={12} xl={7} xxl={8} >
                <Search placeholder="Search 15,000+ Games" onSearch={onSearch} className = "mt-3 w-[95%]" />

                </Col>


                
                
                <Col xs={{span : 11, offset : 0 }} sm = {{span : 8, offset : 0}} md= {{span : 7, offset : 0}} lg = {{span : 5 , offset : 0}} xl= {{span : 4 , offset : 0}} xxl={{span : 3 , offset : 0}}>
                    <div style = {{display : !user ? "none" : "block"}} className='items-center float-right block pl-3 pr-3 hover:bg-raven-mid-blue cursor-pointer ml-2' onClick={openWallet}>
                      
                            <img src = {coin} className='block float-left bg-center bg-contain w-auto xs:mt-6 sm:mt-[22px] xs:h-4 sm:h-5 md:h-6 md:mt-5'/>
                            <span className='ml-1 font-sans xs:text-sm sm:text-lg text-gray-200 font-semibold'>{balance} USD</span>
                           
                      </div>
                    </Col>
                    
                  <Col xs={5} sm = {3} md= {3} lg ={2} xl={2} xxl={1}>

                    { !user? 
                      (
                        <Button type="primary" shape="round" size="default"  className='bg-raven-pink mt-3 w-full' onClick={() => setOpenDrawer(true)}>
                          Login
                        </Button>
                      )
                       : 
                      (
                        <Avatar size="large" icon={<UserOutlined />} className='bg-raven-pink mt-[10px] float-right mb-[10px] cursor-pointer' onClick={openProfile} />
                      )

                    }


                  </Col>
                   
                </Row>
                <Row className='w-full mt-0'>
                <Col xs={24} sm = {24} md= {24} lg ={0} xl={0} xxl={0} >
                <Search placeholder="Search 15,000+ Games" onSearch={onSearch} className = "mt-2 w-full " />
                </Col>

                </Row>
                <Row className='w-full mt-0'>
                <Col xs={24} sm = {24} md= {11} lg ={24} xl={0} xxl={0} >
                <div className='block'>
                
                <NavLink to="/" className="font-semibold text-lg cursor-pointer text-white"  style={({ isActive }) => {
                                          return isActive ? { color: "#c65298" } : {};
                                          }}>Games</NavLink>

                <NavLink to="/tournaments" className="font-semibold ml-10 text-lg cursor-pointer text-white"  style={({ isActive }) => {
                                          return isActive ? { color: "#c65298" } : {};
                                          }}>Tournaments</NavLink>

                
                <NavLink to="/blogs" className="font-semibold text-lg ml-10 cursor-pointer text-white" style={({ isActive }) => {
                                          return isActive ? { color: "#c65298" } : {};
                                          }}>Blogs</NavLink> 
                  </div>
                </Col>

                </Row>
               
              </Header>
              
              <Drawer
                  title= ""
                  placement= "right"
                  closable={true}
                  open={opendrawer}
                  onClose={() => setOpenDrawer(false)}
                  key="right"
              >
                <DrawerComponent />
                
              </Drawer>
              
              <Content className = "mt-4" style = {{marginBottom : "-40px"}}>
                 
              <div id="sidemenuwrapper" className='z-50 fixed xs:hidden sm:hidden md:hidden xl:block' style={{width : '60px'}}>
               <GameSideMenu menucollapse = {menucollapse} onMouseEnter = {onSideBarMouseEnter} onMouseLeave = {() => hideSideBar()} onClick = {onMenuClick} />
               </div>   
                  <div className='block xl:ml-[25px] xxl:ml-[0px]'>
                    <Outlet context={{setOpenDrawer,setDrawerComp}}/>
                  </div> 

                </Content>
        </Layout>
    </div>
  );
}

export default MainLayout; 