
import {useEffect, useState , useRef} from 'react';
import VideoAd from '../components/videoad-component';
import {AdsterraBannerAd , AdsterraBannerAd2 , AdsterraSocialBar} from '../components/adsterra-component';
import { Row , Col } from 'antd';



function AdTestPage(){

    const adRef = useRef(null);

    
    let game = null;

    useEffect(() => {

        game = document.getElementById("game");
   
        
    } , []);

    const callAd = () => {
        console.log('here');
        console.log(adRef);
        game.style.display = "none";

        adRef.current.triggerAd();

        
    }




    return (
        <div>


            <div className='m-auto w-[1000px] h-[480px] relative'>
             {/*
              <div className='absolute top-0 left-0 w-full h-full bg-green-700 z-10' id="game">
                    <p className='text-white text-center pt-[25%]'>SOME CONTENT HERE</p>
              </div>
               
              <VideoAd className = 'absolute top-0 left-0 w-full h-full' ref = {adRef} adUnitPath = "https://pubads.g.doubleclick.net/gampad/ads?iu=/23041303511/ravenarena-videoad-1&description_url=ravenarena.com&tfcd=0&npa=0&sz=400x300%7C640x480&min_ad_duration=10000&max_ad_duration=90000&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=" />
            */}
            </div>
            <Row className='ml-[100px]'>
                <Col>
                <AdsterraBannerAd k="0aa8da7b8a40500640ed0d3eb256d933" height="90" width="728" /> 
                </Col>
            </Row>
            <Row className='ml-[100px]'>

                <Col>

                <AdsterraBannerAd k="cc3c55fd7c7b66658e5122a7baf87d5d" height="600" width="160" /> 

                </Col>
            </Row>
            <Row className='ml-[100px]'>

            <Col>


            <AdsterraBannerAd2 src="//wizardunstablecommissioner.com/watchnew?key=0aa8da7b8a40500640ed0d3eb256d933" width="728" height="90" refreshInterval = {30000} />

            </Col>
            </Row>

           
             
             
           

            


          <AdsterraSocialBar />

            {/* <button className='text-white m-4 ml-[600px] m-auto block w-fit bg-green' onClick={callAd}>Call AD</button> */}
         </div>
      );
}


export default AdTestPage;


    