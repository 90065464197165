
import { Row,Col } from 'antd';
import Games from '../components/gamelist-component';
import Loading from '../components/loading-component';
import {fetchGames} from '../services/game-service';
import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

function NewGamesPage() {

  const [gamesloaded, setGamesLoaded] = useState(false);  
  const [games, setGames] = useState([]); 


  const headerTags = (title, description , image, link) => {

    document.title = title;
    document
          .querySelector('meta[name="description"]')
          .setAttribute('content', description);
    document
          .querySelector('meta[property="og:title"]')
          .setAttribute('content', title);
    document
          .querySelector('meta[property="og:image"]')
          .setAttribute('content', image);
    document
          .querySelector('meta[property="og:url"]')
          .setAttribute('content', link);
    document
          .querySelector('meta[name="twitter:title"]')
          .setAttribute('content', title);
    document
          .querySelector('meta[name="twitter:description"]')
          .setAttribute('content', description);
    document
          .querySelector('meta[name="twitter:image"]')
          .setAttribute('content', image);

  }
 

  useEffect( () => {
    headerTags('Play New Games On Ravenarena', "Join RavenArena to play thousands of free browser games. Enjoy casual, puzzle, battle, shooter, and driving games instantly!" , "https://ravenarena.com/static/media/Logo.639dd7f7732c3f06bdc8.png", window.location) 
             

    setGamesLoaded(false);
    const getGames = async() => {
      let gameslst =  await fetchGames("limit=64&new=true");
      setGames(gameslst);
      setGamesLoaded(true);
    }

    getGames();
    
   
    
  }, []); 

   
    return (
      <div>

        {
           !gamesloaded ? (<Loading />) : (
              <Row>
                
              <Col xs = {{span : 22 , offset : 1}} sm = {{span : 22 , offset : 1}} md = {{span : 22 , offset : 1}}  lg = {{span : 22 , offset : 1}} xl = {{span : 22 , offset : 1}}  xxl = {{span : 22 , offset : 1}}>
                <h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit mb-3'>New Games</h1>
                <Games list = {games} show_pagination />
              </Col>
            </Row>
             ) 



        }

       

         

      </div>
    );
  }
  
  export default NewGamesPage;