import MainLayout from "./components/layout";
import GamesPage from "./pages/gameslist-page";
import GameDetails from "./pages/gamedetails-page";
import FeaturedPage from "./pages/featured-page";
import GamesByCatPage from "./pages/gamelist-cat-page";
import GamesByTagPage from "./pages/gamelist-tag-page";
import NewGamesPage from "./pages/gamelist-new-page";
import GamesSearchPage from "./pages/search-page";
import TournamentComingSoon from "./pages/tournament-coming-soon-page";
import EmailActivate from "./pages/email-activate";
import NewPasswordPage from "./pages/reset-pass-page";
import LikedGamesPage from './pages/liked-gamelist-page';
import PlayLaterPage from './pages/playlater-list-page';
import BlogDetails from './pages/blog-details-page';
import BlogList from './pages/bloglist-page';
import TournamentList from './pages/tournamentlist-page';
import TournamentDetails from "./pages/tournament-details-page";


import AdTestPage from "./pages/adtest-page";


import PrivacyPolicy from './pages/privacy-policy-page';

import { GoogleOAuthProvider } from '@react-oauth/google';
import Loading from "./components/loading-component";
import { Suspense } from "react";
import React from "react";
import Page404 from './pages/404-page';




import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

function App() {



  const router = createBrowserRouter([
    {
      // parent route component
      element: <MainLayout />,
      errorElement: <Page404 />,
      // child route components
      children: [
        {
          path: "/",
          element:  <FeaturedPage />,
        },
        {
          path: "/home",
          element:  <FeaturedPage />,
        },
        // other pages....
        {
          path: "/game/:game_name",
          element: <GameDetails />,
        },
        {
          path: "/new",
          element: <NewGamesPage />,
        },
        {
          path: "/cat/:cat_name",
          element: <GamesByCatPage />,
        },
        {
          path: "/t/:tag_name",
          element: <GamesByTagPage />,
        },
        {
          path: "/search",
          element: <GamesSearchPage />,
        },
        {
          path: "/tournaments",
          element: <TournamentComingSoon />,
        },
        {
          path: "/emailactivate",
          element: <EmailActivate />,
        },
        {
          path: "/newpassword",
          element: <NewPasswordPage />,
        },
        {
          path: "/likes",
          element: <LikedGamesPage />,
        },
        {
          path: "/playlater",
          element: <PlayLaterPage />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/blogs",
          element: <BlogList />,
        },
        {
          path: "/blogs/:blog_name",
          element: <BlogDetails />,
        },
        {
          path: "/tournament/:tournament_name",
          element: <TournamentDetails />,
        },
        {
          path: "/tournaments/",
          element: <TournamentList />,
        },

        {
          path: "/adtest/",
          element: <AdTestPage />,
        },

        

        
      ],
    },
  ])



  return (
    <GoogleOAuthProvider clientId="90624036120-hboasjcu36eotgtifvn099dpdbloja8c.apps.googleusercontent.com">
    <RouterProvider router={router} />
    </GoogleOAuthProvider>
  );
}

export default App;
