import axios from 'axios';

import { apiClient } from './api-config';

export const fetchGames = async (query) => {
  try {
    const response = await apiClient.get('/games/list/?' + query);
    return response.data;
  } catch (error) {
    //console.error('Error fetching games:', error);
    return [];
  }
};

export const fetchGameDetails = async (name) => {
    try {
      const response = await apiClient.get('/games/detail/' + name + '/');
      return response.data;
    } catch (error) {
      //console.error('Error fetching game details:', error);
      return null;
    }
  };

  export const fetchLikedGames = async (query) => {
    try {
      const response = await apiClient.get('/games/list/liked/?' + query);
      return response.data;
    } catch (error) {
      return {"count": 0,
              "next": null,
              "previous": null,
              "games": []};
    }
  };

  export const fetchPlayLaterGames = async (query) => {
    try {
      const response = await apiClient.get('/games/list/playlater/?' + query);
      return response.data;
    } catch (error) {
      return {"count": 0,
              "next": null,
              "previous": null,
              "games": []};
    }
  };


  export const updateSentiment = async (game , type) => {
    try {
      const response = await apiClient.post('/games/update/sentiment/' , {game : game , type : type} );
      return true;
    } catch (error) {
      return false;
    }
  };

  export const updatePlayLater = async (game , type) => {
    try {
      const response = await apiClient.post('games/update/playlater/' , {game : game , type : type} );
      return true;
    } catch (error) {
      return false;
    }
  };
  
  export const addPlay = async (game) => {
    try {
      const response = await apiClient.post('games/addplay/' , {game : game} );
      return response.data;
    } catch (error) {
      return false;
    }
  };


  export const endPlay = async (session) => {
    try {
      const response = await apiClient.post('games/enp/' , {session : session} );
      return true;
    } catch (error) {
      return false;
    }
  };

  export const sendFeedback = async (game, issue , message) => {
    try {
      const response = await apiClient.post('games/feedback/submit/' , {game : game , issue : issue , message : message} );
      return true;
    } catch (error) {
      return false;
    }
  };


  


  


 
  
  