

import { Input , Select , Button , Form} from 'antd';
import {CopyOutlined } from '@ant-design/icons';
import {notification} from 'antd';

function DepositAddressPage(props) {

  
    const copyLink = () => {
        navigator.clipboard.writeText(localStorage.getItem('deposit_address'));
  
        notification.info({
          message: `Address Copied !`,
          description: '',
          placement : 'bottomRight',
          duration : '2',
        });
  
      }

    const done = () => {
      props.notify('Deposit In Progress!' , 'The deposited amount will appear in your wallet with in a few minutes.')
      props.cngDrawerComp('wallet')
    }


    return (
      <div>
         <span className='font-semibold text-raven-pink text-2xl w-full block text-center mt-10'>Deposit</span>

         <span className=' text-gray-300 text-base w-full block mt-10'>Please deposit at the address specified below.</span>


           <div className='w-[210px] h-[210px] bg-gray-400 block m-auto mt-10'>
             <img src={process.env.REACT_APP_API_BASE_URL + '/wallet/qr/' + localStorage.getItem('deposit_address') + '/'} w={210} h={210} />
           </div>

           <div className = "bg-raven-mid-blue w-full p-5 mt-5 rounded-xl">
           <span className=' text-gray-300 text-sm w-full block'>Network</span>
           <span className='font-semibold text-white text-base w-full block'>BNB Smart Chain(BEP20)</span>
           <span className=' text-gray-300 text-sm w-full block mt-3'>Address</span>
           

           <div className="font-semibold text-white text-base w-full flex items-center flex-wrap">
  <span className="w-[90%] break-words" >
  {localStorage.getItem('deposit_address')}
  </span>
  <CopyOutlined className="ml-2" onClick={copyLink}/>
</div>
          </div>
          
        <Button type="primary" onClick={done} shape="round"  htmlType="submit" size="default"  className='bg-raven-pink mt-5 h-12 w-full text-lg' >
            Done
        </Button>
        <span className='text-gray-300 cursor-pointer mt-3 m-auto block w-fit text-[17px] font-semibold' onClick={() => props.cngDrawerComp('wallet')}>
            Cancel
        </span>
      </div>
    );
  }
  
  export default DepositAddressPage;