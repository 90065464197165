
import { Col, Row , Image , Button , Alert , DatePicker , Input , Select , Form , message } from 'antd';
import {checkUsername , checkEmail , register} from '../services/auth-service';
import {sendFeedback} from '../services/game-service';
import {useEffect, useState } from 'react';
import { useLocation , useParams } from 'react-router-dom';

const { TextArea } = Input;

function FeedBack(props) {

  let {game_name} = useParams();
  let {tournament_name} = useParams();
  
  const [loading, setLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = (values) => {

      setLoading(true);

      let name = game_name != null ? game_name : tournament_name;
    
      const execFeedback = async() => {
        let result =  await sendFeedback( name, values.issue , values.message);
        if(result){
          setLoading(false);
          
          messageApi.open({
            type: 'success',
            content: 'Thank you for your feedback',
          });
          await new Promise(resolve => setTimeout(resolve, 1000));
          props.cngDrawerComp('profile');
          props.setOpenDrawer(false);
          
        }
        else{
          setLoading(false);
          messageApi.open({
            type: 'error',
            content: 'Unable to send Feedback! Please try again later!',
          });
          
        }
      }
      execFeedback();




     



    
  }

  const onFinishFailed = (errorInfo) => {
    
  }


  

  const [form] = Form.useForm();
 
    return (
      <div>
        {contextHolder}
           <span className='font-semibold text-raven-pink text-2xl w-full block text-center mt-10'>Send Your Feedback</span>

          
           <div  className='block mt-6'>
          <Alert
            message="Error Messages"
            type="error"
            showIcon
            className='mt-3 bg-red-300 border-red-300 text-red-800 rounded-3l hidden'
          />
          
           <Form
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
           
           <Form.Item name = "issue" rules={[{ required: true, message: 'Please select the issue!' }]}>
           <Select placeholder="Issue Type" className='h-12 rounded-3xl bg-raven-mid-blue w-full'  options={[



                        { value: "not working", label: "The game is not working"},
                        { value: "suggestion", label: "I have a suggestion"},
                        { value: "other", label: "Other"},
                    ]} />
                    </Form.Item>
            <Form.Item name = "message" rules={[{ required: true, message: 'Please add your comment!' }]}>
                
                <TextArea placeholder="Type your comment" className='rounded-3xl bg-raven-mid-blue mt-3' style = {{height : 100}}  maxLength={1000} />


            </Form.Item>
            <Form.Item>
            <Button type="primary" shape="round" size="default" htmlType="submit" className='bg-raven-pink h-12 w-full text-lg' loading = {loading} >
              Submit
            </Button>
             </Form.Item>
            
            </Form>  
            </div>
             
            



      </div>
    );
  }
  
  export default FeedBack;