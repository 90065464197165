import img404 from '../assets/404.png';
import {Button} from 'antd';

function Page404() {
  
      return (
        <div className="w-full h-full flex justify-center items-center pt-[10%] place-content-center ">
           

           <div className='block align-center  place-content-center '>
          <img src = {img404} width = {300}/>
          <Button href='/' type="primary" shape="round" size="default"  className='bg-raven-pink mt-10 h-10 text-lg items-center block'>
               Go To Home Page
            </Button>
            </div>



        </div>
      )
}

export default Page404; 
  