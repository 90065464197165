
import { Row,Col,Pagination } from 'antd';
import GameCard from '../components/gamecard-component';
import {fetchGames} from '../services/game-service';
import {useRef, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Loading from '../components/loading-component';



function Games(props) {

  const [games, setGames] = useState({games : [] , count : 0 , next : 2}); 
  const [gamesloaded, setGamesLoaded] = useState(true);  
  const [currentpage , setCurrentpage] = useState(1);
  const parentRef = useRef(null);
  const [span, setSpan] = useState(0);
  const navigate = useNavigate();

  useEffect( () => {

    if (parentRef.current) {
      let w = parentRef.current.getBoundingClientRect().width;
      if(w > 1200){
        setSpan(3)
      }
      else if(w > 800){
        setSpan(4)
      }
      else if(w > 500){
        setSpan(6)
      }
      else if(w >= 300){
        setSpan(12)
      }
      else{
        setSpan(24)
      }

      
    }


    if(props.list){
      setGames(props.list);
    }
    else{
      const getGames = async() => {
        setGamesLoaded(false);
        let gameslst =  await fetchGames(props.query);
        setGames(gameslst);
        setGamesLoaded(true);
      }

      getGames();


    }
   
    
  }, []); 


  const onGameClick = (game_name) => {
    navigate('/game/' + game_name);
   }

  const pageChange = (pageNumber) => {
    const getGames = async() => {
      setGamesLoaded(false);
      let gameslst =  await fetchGames("page="+pageNumber);
      setGames(gameslst);
      setCurrentpage(pageNumber);
      setGamesLoaded(true);
    }
    getGames();
  };

    return (
      <div ref={parentRef}>

          {
           !gamesloaded ? (<Loading />) : (

            <div>
              <Row className = "mb-5">
                {
                  games.games.map(
                    g => (
                      <Col span={span}>
                        <GameCard image = { process.env.REACT_APP_MEDIA_URL + g.thumbnail} title = {g.title} onClick = {() => onGameClick(g.name)}/>
                      </Col>
                    )
                  )
                
                }

              </Row>
              <Pagination current={currentpage} pageSize = {60} showLessItems = {true} pageSizeOptions = {[60]} total={games.count} className='m-auto w-fit mb-[20px]' onChange={pageChange} style = {{display : props.show_pagination ? 'block' : 'none'}}/>
            </div>
         )
        }
      </div>
    );
  }
  
  export default Games;