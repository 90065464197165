import { apiClient } from './api-config';

export const fetchBlogs = async (query) => {
  try {
    const response = await apiClient.get('/blogs/list/?' + query);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchBlogDetails = async (name) => {
    try {
      const response = await apiClient.get('/blogs/' + name + '/');
      return response.data;
    } catch (error) {
      return null;
    }
  };



  export const endBlogRead = async (session) => {
    try {
      const response = await apiClient.post('/blogs/end/enp/' , {session : session});
      return true;
    } catch (error) {
      return false;
    }
  };