
import {Button , Alert , Form} from 'antd';
import { FacebookLoginButton , GoogleLoginButton } from "react-social-login-buttons";
import { Input } from 'antd';
import {login , googleLogin} from '../services/auth-service';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import React, { useState} from 'react';

function Login(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errmsg , setErrMsg] = useState(null);

  

  const onFinish = (values) => {

    const execlogin = async() => {
      setLoading(true);
      let result = await login(values['email'] , values['password']);
      if(result[0]){
        
        setLoading(false);
        console.log('login success');
        props.setUser(true);
        props.setOpenDrawer(false);
        props.cngDrawerComp('profile');
        window.location.reload();

      }
      else{
        setLoading(false);
        setErrMsg(result[1]);
      }
    }
    execlogin();


  }

  const loginGoogle = useGoogleLogin({
    onSuccess: codeResponse => processGoogleLogin(codeResponse.code),
    flow: 'auth-code',
    
  });

  const processGoogleLogin = async(token) => {
    let result = await googleLogin(token);
    if(result[0]){
      props.setUser(true);
      props.cngDrawerComp('profile');
      props.setOpenDrawer(false);
      window.location.reload();
    }
    else{
      setLoading(false);
      setErrMsg(result[1]);
    }

  }

  

  

    return (
      <div>
        
           <span className='font-semibold text-raven-pink text-2xl w-full block text-center mt-10'>Log in</span>

           <div className='block mt-9'>
            <FacebookLogin
              appId="773334038143948"
              className='w-full hidden'
              onSuccess={(response) => {
                console.log('Login Success!', response);
              }}
              onFail={(error) => {
                console.log('Login Failed!', error);
              }}
              onProfileSuccess={(response) => {
                console.log('Get Profile Success!', response);
              }}
            >
              <FacebookLoginButton align = "center" style = {{borderRadius : '24px',  height : '48px'}}> <span>Continue with Facebook</span></FacebookLoginButton>
           
            </FacebookLogin>
             <GoogleLoginButton onClick={() => loginGoogle()} align = "center" style = {{borderRadius : '24px',  height : '48px' , marginTop : '10px' }} ><span>Continue with Google</span></GoogleLoginButton>
           </div>
           <span className='font-semibold text-raven-pink text-xl w-full block text-center mt-6'>OR</span>
           <div  className='block mt-6'>
              <Alert
                message={errmsg}
                type="error"
                className='mt-3 bg-red-300 border-red-300 text-red-800 rounded-3l'
                style = {{display : errmsg ? "block" : "none"}}
              />
              <Form
                form={form}
                name="basic"
                initialValues={{ remember: false }}
                onFinish={onFinish}
                className='mt-2'
                autoComplete="off"
              >
              <Form.Item name = "email"  rules={[{ required: true, message: 'Please input your username!' }]}>
                 <Input status="error" placeholder="Email or Username" className='h-12 rounded-3xl bg-raven-mid-blue mt-1' />
              </Form.Item>
              <Form.Item name = "password"  rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password status="" placeholder="Password" className='h-12 rounded-3xl bg-raven-mid-blue mt-1' />
              </Form.Item>
              <Form.Item>
              <span className='font-semibold float-right block text-raven-light-blue mt-1 cursor-pointer' onClick={() =>  props.cngDrawerComp('forgot_pass')}>Forgot Password?</span>
               <Button type="primary" shape="round" size="default" htmlType="submit"  className='bg-raven-pink mt-4 h-12 w-full text-lg' loading = {loading}>
                   Login
               </Button>
              </Form.Item>
              

              </Form>
            </div>
            
            <span className='font-semibold float-left block text-raven-light-blue mt-2 w-full text-center text-base'>New to RavenArena? <span className='text-raven-pink cursor-pointer' onClick = {() => props.cngDrawerComp('register')}>Join Now</span></span>
            

      </div>
    );
  }
  
  export default Login;