
import trophy from '../assets/trophy.webp';
import {Button} from 'antd';
import {Routes, Route, useNavigate ,NavLink} from 'react-router-dom';

function TournamentBar() {

    const navigate = useNavigate();

    return(
          <div className="w-100 h-[80px] bg-green-700 flex mb-4 mt-[-1rem] cursor-pointer" onClick={() => navigate('/tournaments')}>
                <div className='flex m-auto'>
                    <img src={trophy} className='pt-2 md:h-[60px] sm:h-[30px] xs:h-[30px]'/>
                    <div className='ml-2 mr-2'>
                    <h1 className = "text-[#fff] text-center mt-3  md:text-2xl sm:text-lg xs:text-lg font-sans">Join tournaments and compete with others</h1>
                    
                    
                    </div>
                    <img src={trophy} className='pt-2 md:h-[60px] sm:h-[30px] xs:h-[30px] '/>
                    <Button className='ml-2 md:mt-4 sm:mt-4 bg-white text-black hover:bg-green-300 hover:text-white font-bold xs:hidden sm:block md:block'  onClick={() => navigate('/tournaments')}>Learn More</Button>
                </div>
          </div>
    )
}

export default TournamentBar;