import {getTournamentList} from '../services/tournament-service';
import {useEffect, useState , useRef} from 'react';
import TournamentCard from '../components/tournamentcard-component';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { Row,Col ,Pagination} from 'antd';
import '../App.css';
import Loading from '../components/loading-component';



function TournamentList(){
   
    const [tlist , setTournamentsList] = useState([]);
    const [tloaded, setTournamentsLoaded] = useState(false);  
    const navigate = useNavigate();


    useEffect(() => {
        const getTList = async() => {
            let ts =  await getTournamentList();
            setTournamentsList(ts);
            setTournamentsLoaded(true);
        }
        getTList();
    } , []);

    const onTClick = (b) => {
        navigate('/tournament/' + b);
    }



    return (
        <div>
  
          {
             !tloaded ? (<Loading />) : (
                <Row>
                <Col xs = {{span : 22 , offset : 1}} sm = {{span : 22 , offset : 1}} md = {{span : 22 , offset : 1}}  lg = {{span : 22 , offset : 1}} xl = {{span : 22 , offset : 1}}  xxl = {{span : 22 , offset : 1}}> 
                  <div>
                  <h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit mb-3'>Tournaments</h1>
                  
                    <Row className = "mb-5">
                        {
                            tlist.map(
                                t => (
                                    <Col xs={24} sm = {24} md= {12} lg ={12} xl={8} xxl={8}>
                                        <TournamentCard image = {process.env.REACT_APP_MEDIA_URL + t.thumbnail} tournament = {t.name}  entry = {t.entry_fee} date_diff = {t.diff} game = {t.game_name} top_reward = {t.first_place_winnings} onClick = {() => onTClick(t.name)}/>
                                    </Col>
                                )
                            )
                        }
                    </Row>
                  </div>
                </Col>
              </Row>
               )
          }
        </div>
      );
}


export default TournamentList;


    