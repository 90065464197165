
import { Row,Col } from 'antd';
import Games from '../components/gamelist-component';
import Loading from '../components/loading-component';
import {fetchGames} from '../services/game-service';
import {useEffect, useState} from 'react';
import TournamentComingSoonBar from '../components/tournaments-coming-soon-bar-component';
import { useParams , useSearchParams } from 'react-router-dom';

function GamesSearchPage() {

  const [gamesloaded, setGamesLoaded] = useState(false);  
  const [games, setGames] = useState([]); 
  let [params , setSearchParams] = useSearchParams();
 

  useEffect( () => {

    const getGames = async() => {
      setGamesLoaded(false);
      let gameslst =  await fetchGames("limit=60&search="+params.get('q'));
      setGames(gameslst);
      setGamesLoaded(true);
    }

    getGames();
   
    
  }, [setSearchParams]); 

   
    return (
      <div>

        {
           !gamesloaded ? (<Loading />) : (
              <Row>
              <Col xs = {{span : 22 , offset : 1}} sm = {{span : 22 , offset : 1}} md = {{span : 22 , offset : 1}}  lg = {{span : 22 , offset : 1}} xl = {{span : 22 , offset : 1}}  xxl = {{span : 22 , offset : 1}}>
                <span className='text-white text-2xl font-bold font-sans mt-5 mb-5 block'>Results for - {params.get('q')}</span>
                <Games list = {games} show_pagination />
              </Col>
            </Row>
             ) 



        }

       

         

      </div>
    );
  }
  
  export default GamesSearchPage;