import {fetchBlogs} from '../services/blog-service';
import {useEffect, useState , useRef} from 'react';
import BlogCard from '../components/blogcard-component';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { Row,Col ,Pagination} from 'antd';
import '../App.css';
import Loading from '../components/loading-component';



function BlogList(){
   
    const [blog_list , setBlogList] = useState([]);
    const [blogsloaded, setBlogsLoaded] = useState(false);  
    const [currentpage , setCurrentpage] = useState(1);
    const navigate = useNavigate();


    useEffect(() => {
        const getBlogList = async() => {
            let blogs =  await fetchBlogs();
            setBlogList(blogs);
            setBlogsLoaded(true);
        }
        getBlogList();
    } , []);

    const onBlogClick = (b) => {
        navigate('/blogs/' + b);
    }

    const pageChange = (pageNumber) => {
        const getBlogList = async() => {
          setBlogsLoaded(false);
          let blogs =  await fetchBlogs("page="+pageNumber);
          setBlogList(blogs);
          setCurrentpage(pageNumber);
          setBlogsLoaded(true);
        }
        getBlogList();
    };


    return (
        <div>
  
          {
             !blogsloaded ? (<Loading />) : (
                <Row>
                <Col xs = {{span : 22 , offset : 1}} sm = {{span : 18 , offset : 3}} md = {{span : 22 , offset : 1}}  lg = {{span : 22 , offset : 1}} xl = {{span : 20 , offset : 2}}  xxl = {{span : 18 , offset : 3}}> 
                  <div>
                  <h1 class="xs:text-xl sm:text-xl lg:text-3xl font-bold tracking-tight text-gray-300 mt-10 mb-10">Blogs</h1>
                    <Row className = "mb-5">
                        {
                            blog_list.blogs.map(
                                b => (
                                    <Col xs={24} sm = {24} md= {12} lg ={12} xl={8} xxl={8}>
                                        <BlogCard image = {b.thumbnail} snippet = {b.snippet_text} title = {b.title} date = {b.published_date} onClick = {() => onBlogClick(b.permalink)}/>
                                    </Col>
                                )
                            )
                        }
                    </Row>
                    <Pagination current={currentpage} pageSize = {20} showLessItems = {true} pageSizeOptions = {[20]} total={blog_list.count} className='m-auto w-fit mb-[20px]' onChange={pageChange} />
                   </div>
                </Col>
              </Row>
               )
          }
        </div>
      );
}


export default BlogList;


    