
import { Image } from 'antd';
import logo from '../assets/Logo.png';
import { useState } from 'react';

function BlogCard(props) {
   
  
  
    return (

      <div className='mr-2 mt-2 bg-[#222] h-[550px] rounded-xl'>
        <div className='bg-[#222] rounded-2xl cursor-pointer'  onClick={props.onClick}>
          <img alt = {props.image} src = {props.image} className='rounded-xl w-[100%] bg-cover block h-[300px]' />
        </div>

        <h1 className='text-white font-semibold text-2xl mt-4 cursor-pointer pl-[10px] pr-[10px] h-[100px] line-clamp-3' onClick={props.onClick}>{props.title}</h1>

        <p className="mt-1 font-normal text-gray-700 dark:text-gray-400 line-short pl-[10px] pr-[10px] h-[70px]">{props.snippet}</p>

        <div className='flex mt-1 pl-[10px] pr-[10px] h-[80px]'>
          <span className="text-raven-pink">Ravenarena</span>
          <span className="text-gray-400 ml-2 mr-2"> • </span>
          <span className="text-raven-pink">{new Date(props.date).toLocaleDateString('en-us', {month:"long", day:"numeric" , year:"numeric"})}</span>
        </div>
      
      </div>

    );
  }
  
  export default BlogCard;