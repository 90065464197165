

import { Row,Col } from 'antd';
import Games from '../components/gamelist-component';
import Loading from '../components/loading-component';
import {fetchLikedGames} from '../services/game-service';
import {useEffect, useState} from 'react';


function LikedGamesPage() {

  const [gamesloaded, setGamesLoaded] = useState(false);  
  const [games, setGames] = useState({"count": 0,
                                        "next": null,
                                        "previous": null,
                                        "games": []}); 
 

  useEffect( () => {
    setGamesLoaded(false);
    const getGames = async() => {
      let gameslst =  await fetchLikedGames("limit=60");
      setGames(gameslst);
      setGamesLoaded(true);
    }

    getGames();
   
    
  }, []); 

   
    return (
      <div>

        {
           !gamesloaded ? (<Loading />) : (
              <Row>
                
              <Col xs = {{span : 22 , offset : 1}} sm = {{span : 22 , offset : 1}} md = {{span : 22 , offset : 1}}  lg = {{span : 22 , offset : 1}} xl = {{span : 22 , offset : 1}}  xxl = {{span : 22 , offset : 1}}>
                <h1 className='text-white xs:text-lg sm:text-lg md:text-xl font-bold font-sans w-fit mb-3'>Liked Games</h1>
                <Games list = {games} show_pagination />
              </Col>
            </Row>
             ) 



        }

       

         

      </div>
    );
  }
  
  export default LikedGamesPage;