import axios from 'axios';
import { apiClient , apiClient2 } from './api-config';

export const login = async (email , password) => {
    try {
      const response = await apiClient.post('/account/login/' , {email : email , password : password});
      localStorage.setItem('token', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      const profile = await apiClient.get('/account/get_account_details/');
      localStorage.setItem('profile' , JSON.stringify(profile.data.account_details));
      return [true , response.data];
    } catch (error) {
      return [false, error.response.data.message];
    }
};

export const logout = async () => {
  try {
    const response = await apiClient.post('/account/logout/');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('profile');
    return true;
  } catch (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('profile');
    return false;
  }
};




export const refreshToken = async (refresh_token) => {
  try {
    const response = await apiClient2.post('/account/token/refresh/' , {refresh : refresh_token});
    localStorage.setItem('token', response.data.access);
    localStorage.setItem('refresh', response.data.refresh);
    return [true , response];
  } catch (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('profile');
    return [false , error];
  }
};


export const register = async ({username , email , gender ,dob,country , password , referred_by}) => {
  try {
    const body = {username : username , email : email , gender : gender , dob : dob , country : country ,  password : password , referred_by : referred_by};
    const response = await apiClient.post('/account/register/' ,
             body);
    return response.data;
  } catch (error) {
    //console.error('Error fetching games:', error);
    return false;
  }
};

export const checkUsername = async (username) => {
  try {
    const response = await apiClient.post('/account/chkusername/' , {username : username});
    return true;
  } catch (error) {
    return false;
  }
};

export const checkEmail = async (email) => {
  try {
    const response = await apiClient.post('/account/chkemail/' , {email : email});
    return true;
  } catch (error) {
    return false;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await apiClient.post('/account/forgotpassword/' , {email : email});
    return [true, response.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const resetPassword = async (uidb64 , token , new_password) => {
  try {
    const response = await apiClient.post('/account/passwordreset/' , {uidb64 : uidb64 , token : token , new_password : new_password});
    return [true, response.data.message];
  } catch (error) {
    return [false, error.response.data.message];
  }
};

export const emailActivate = async (uid , token) => {
  try {
    const response = await apiClient.get('/account/emailconfirm/' + uid + '/' + token + '/');
    return true;
  } catch (error) {
    return false;
  }
};


export const googleLogin = async (token) => {
  try {
    const response = await apiClient.post('/account/google_login/' , {token : token});
    localStorage.setItem('token', response.data.access);
    localStorage.setItem('refresh', response.data.refresh);
    const profile = await apiClient.get('/account/get_account_details/');
    localStorage.setItem('profile' , JSON.stringify(profile.data.account_details));
    return [true, response.data];
  } catch (error) {
    return [false, error.response.data.message];
  }
};












