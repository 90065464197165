

import { Input , Select , Button , Form , Alert} from 'antd';
import {withdraw , getBalance} from '../services/wallet-service';
import React, {useEffect , useState} from 'react';

function WithdrawPage(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errmsg , setErrMsg] = useState(null);
  const [balance , setBalance] = useState("-.--");

  useEffect( () => {

    const getAccountBalance = async() => {
      let p =  await getBalance();
      if(p != null){
        setBalance(p);
      }
    }
    getAccountBalance();

    const intervalId = setInterval(getAccountBalance, 30000);

    return () => {
      // This function will be called when the component is unmounted (destroyed)

      if(intervalId != null){
        clearInterval(intervalId);
      }
    };
    
  }, []); 


  const onFinish = (values) => {

    const makeWithdrawal = async() => {
      setLoading(true);
      let response = await withdraw(values.amount, values.address);
      if(response[0]){
        setLoading(false);
        props.notify('Withdrawal In Progress!' , 'The withdrawn amount will appear in your crypto account with in a few minutes.')
        props.cngDrawerComp('wallet');
      }
      else{
        setLoading(false);
        setErrMsg(response[1]);
      }
    }
    makeWithdrawal();


  }


    return (
      <div>
         <span className='font-semibold text-raven-pink text-2xl w-full block text-center mt-10'>Withdraw</span>
          <div className = "bg-raven-mid-blue w-full p-5 mt-5 rounded-xl">
           <span className=' text-gray-300 text-base w-full block'>Balance</span>
          <span className='font-semibold text-white text-2xl w-full block mt-2'>${balance}</span>
          </div>

          <span className=' text-gray-300 text-base w-full block mt-10'>Withdraw</span>

          <p className='text-red-500 mt-1 w-full'>Please make sure to enter the correct address, currency and network. Incorrect entry can cause loss of money and is irreversible.</p>

          <Alert
                message={errmsg}
                type="error"
                className='mt-3 bg-red-300 border-red-300 text-red-800 rounded-3l'
                style = {{display : errmsg ? "block" : "none"}}
              />

          <Form
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={null}
            autoComplete="off"
          >
            
          <Form.Item name = "amount" hasFeedback   rules={[{required: true , message: 'Please enter an amount!' },{ type : 'number' ,  min: 1, transform(value) {
                                                                                                                        return Number(value); // Transform the input value to number type before validation
                                                                                                         },message: 'Minimum withdrawal is $1' }]}>
            <Input status="error" placeholder="Amount" className='h-12 rounded-3xl bg-raven-mid-blue mt-3' />
          </Form.Item>
          <Form.Item name="currency" rules={[{required: true , message: 'Please select a currency!' }]}>
          <Select placeholder="Currency" className='h-12 rounded-3xl bg-raven-mid-blue w-full'   options={[
                        
                        { value: 'USDT', label: 'USDT' },
                     
                    ]} />
          </Form.Item>
          <Form.Item name="network" rules={[{required: true , message: 'Please select a network!' }]} >
          <Select placeholder="Network" className='h-12 rounded-3xl bg-raven-mid-blue w-full'  options={[
                        
                        { value: 'BSC', label: 'BEP20' },
                     
                    ]} />
          </Form.Item>
          <Form.Item name = "address" rules={[{required: true , message: 'Please enter an address of your cypto account!' }]}>
          <Input placeholder="Address" className='h-12 rounded-3xl bg-raven-mid-blue' />
          </Form.Item>
          <Form.Item>
            <Button type="primary" shape="round"  htmlType="submit" size="default"  className='bg-raven-pink mt-1 h-12 w-full text-lg' loading={loading}>
              Withdraw
            </Button>
            </Form.Item>

            </Form>
            <span className='text-gray-300 cursor-pointer mt-3 m-auto block w-fit text-[17px] font-semibold' onClick={() => props.cngDrawerComp('wallet')}>
              Cancel
            </span>
      </div>
    );
  }
  
  export default WithdrawPage;