
import { Row,Col,Result,Button } from 'antd';
import Games from '../components/gamelist-component';
import Loading from '../components/loading-component';
import {emailActivate} from '../services/auth-service';
import {useEffect, useState} from 'react';
import TournamentComingSoonBar from '../components/tournaments-coming-soon-bar-component';
import { useParams , useSearchParams } from 'react-router-dom';

function EmailActivate() {

  const [processed, setProcessed] = useState(false);
  let [params , setSearchParams] = useSearchParams();
  const [result, setResult] = useState(false);

  useEffect( () => {

    const activateEmail = async() => {
      setProcessed(false);
      let result =  await emailActivate(params.get('uid') , params.get('token'));
      setResult(result);
      setProcessed(true);
    }

    activateEmail();  
    
  }, []); 

   
    return (
      <div>

       {
           !processed ? (<Loading />) : (
              <div className='bg-raven-blue-black block w-fit m-auto justify-center rounded-lg pl-10 pr-10'>
                   <Result
                        title={result ? "Email Confirmed Successfully!" : "Activation Invalid or Expired"}
                        status = {result ? "success" : "error"}
                        extra={
                          <Button type="primary" shape="round" size="default"  className='bg-raven-pink mt-5 h-12 w-full text-lg pt-2' href="/">
                            Continue Gaming
                          </Button>
                       
                        }
                    />
              </div>
             ) 



        }

      </div>
    );
  }
  
  export default EmailActivate;