import React, { useEffect, useState , useRef } from 'react';

export function AdsterraBannerAd({k , width , height}){

    const getId = (slot) => `atContainer-${slot}`;

    const banner = useRef();

    const atOptions = {
        key: k,
        format: 'iframe',
        height: height,
        width: width,

        params: {},
    }


      useEffect(() => {

        if (banner.current && !banner.current.firstChild) {
            const conf = document.createElement('script')
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `//www.highperformancedformats.com/${atOptions.key}/invoke.js`
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
    
            banner.current.append(conf)
            banner.current.append(script)
        }
 
  }, []);

  return (

    <div ref={banner} />
    
    
    
  );
};

export function AdsterraSocialBar(){

    const socialbar = useRef();



      useEffect(() => {

        if (socialbar.current && !socialbar.current.firstChild) {
            
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `//pl22443956.profitablegatecpm.com/d7/df/4d/d7df4dc0dae3bef1be44bd21288f747e.js`
            socialbar.current.append(script)
        }
 
  }, [socialbar]);

  return (

    <div ref={socialbar} />
    
    
  );
}


export function AdsterraBannerAd2({src, width, height , refreshInterval}) {
  const adContainerRef = useRef();
  const [intervalId, setIntervalId] = useState(null);

  const createIframe = () => {
    if (adContainerRef.current) {
      
      // Clear existing iframe(s)
      adContainerRef.current.innerHTML = '';

      // Create a new iframe element
      const iframe = document.createElement('iframe');
      iframe.src = src;
      iframe.width = width;
      iframe.height = height;
      iframe.frameBorder = '0';
      iframe.scrolling = 'no';

      // Append the new iframe to the container
      adContainerRef.current.appendChild(iframe);
    }
  };

  const startInterval = () => {
    const iid = setInterval(() => {
        createIframe(); 
      }, refreshInterval);

    setIntervalId(iid);

  }

  const stopInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };


  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      // Page is active, resume or start the interval
      if (!intervalId) {
        startInterval();
      }
    } else {
      // Page is not active, pause the interval
      stopInterval();
    }
  };



  useEffect(() => {

    createIframe();
    startInterval();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Cleanup interval on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      stopInterval();
    }
     
      
  }, []); // Re-run the effect if any of these props change

  return (

    <div ref={adContainerRef}></div>

  );
}



export function AdsterraResponsiveBannerAd({src, width, height , refreshInterval}) {
  const adContainerRef = useRef();

  const createIframe = () => {
    if (adContainerRef.current) {
      // Clear existing iframe(s)
      adContainerRef.current.innerHTML = '';

      // Create a new iframe element
      const iframe = document.createElement('iframe');
      iframe.src = src;
      iframe.width = width;
      iframe.height = height;
      iframe.frameBorder = '0';
      iframe.scrolling = 'no';

      // Append the new iframe to the container
      adContainerRef.current.appendChild(iframe);
    }
  };

  useEffect(() => {

    createIframe();

    let interval = null
    if(refreshInterval != null){

      interval = setInterval(() => {
        createIframe(); 
      }, refreshInterval);

    }
    

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
     
      
  }, []); // Re-run the effect if any of these props change

  return (

    <div ref={adContainerRef}></div>

  );
}



	

	