
import { Image} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import logo from '../assets/Logo.png';

function Loading() {

  
    return (
      <div className='z-50 fixed top:0 inset-0 left:0 w-screen min-h-screen bg-raven-pink opacity-30 flex items-center justify-center'>
        <LoadingOutlined className = 'text-[50px] text-white' />
      </div>

    );
  }
  
  export default Loading;